import { Mesh, BoxGeometry, MeshLambertMaterial, MeshBasicMaterial, CylinderGeometry } from "three";
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';

class CustomTool extends Mesh {
    constructor(toolType, robotName) {
        let geometry, material;

        if (toolType === "gripper") {
            let boxGeometries;
            if (["SCARA", "R6"].includes(robotName)) {
                boxGeometries = [
                    new BoxGeometry(0.12, 0.2, 0.05).translate(0, -0.001, 0),
                    new BoxGeometry(0.12, 0.05, 0.15).translate(0, 0.075, 0.08),
                    new BoxGeometry(0.12, 0.05, 0.15).translate(0, -0.075, 0.08)
                ];
            } else if (robotName === "R3_Planar") {
                boxGeometries = [
                    new BoxGeometry(0.05, 0.2, 0.12).translate(-0.01, 0, 0),
                    new BoxGeometry(0.15, 0.05, 0.12).translate(0.05, 0.075, 0),
                    new BoxGeometry(0.15, 0.05, 0.12).translate(0.05, -0.075, 0)
                ];
            } else {
                boxGeometries = [
                    new BoxGeometry(0.12, 0.2, 0.05).translate(0, -0.001, 0),
                    new BoxGeometry(0.12, 0.05, 0.15).translate(0, 0.075, 0.08),
                    new BoxGeometry(0.12, 0.05, 0.15).translate(0, -0.075, 0.08)
                ];
            }

            geometry = BufferGeometryUtils.mergeGeometries(boxGeometries);
            material = new MeshLambertMaterial({ color: 0x4d4d4d });
        } else if (toolType === "bohrer") {
            const Geometries = [
                new CylinderGeometry(0.02, 0.02, 0.11, 35).translate(0, 0.05, 0),
                new CylinderGeometry(0, 0.025, 0.08, 35).translate(0, 0.13, 0),
            ];
            geometry = BufferGeometryUtils.mergeGeometries(Geometries);
            material = new MeshBasicMaterial({ color: 0x808080 });
        } else {
            geometry = new BoxGeometry(0.1, 0.1, 0.1);
            material = new MeshLambertMaterial({ color: 0xff0000 });
        }

        super(geometry, material);

        if (toolType === "bohrer") {
            this.rotation.x = Math.PI / 2;
        }
    }
}

export default CustomTool;