import React, {Component} from 'react';
import {ListGroup, Row, Col, Button, Card} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../store.js';
import Sortable from 'react-sortablejs-guten/Sortable';
import CommandModel from '../../model/CommandModel';
import Command from '../elements/Command';
import CommandForm from '../forms/CommandForm';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

class CommandsView extends Component {

    componentDidMount() {
        this.initSortable();
    }

    componentDidUpdate() {
        this.initSortable();
    }

    initSortable() {
        let el = document.getElementById('items');
        if (el) {
            Sortable.create(el, {
                handle: ".sort-handle",
                onEnd: this.saveCommandSort
            });
        }
    }

    addCommand() {
        store.commands.push(new CommandModel());
        store.refreshCommandsIndex();
        store.commandValueChanged();
    }

    saveCommandSort(evt) {
        let commands = [];
        for (let x of Array.from(evt.from.children)) {
            commands.push(store.commands[x.getAttribute('index')]);
        }
        store.commands = commands;
        store.refreshCommandsIndex();
        store.commandValueChanged();
    }

    selectCommand(e) {
        if (e.target.getAttribute('isremovebutton') !== 'true') {
            e.stopPropagation();
            store.selectedCommand = e.target.getAttribute('commandid');
        }
    }

    render() {

        let {selectCommand} = this;

        return store.robot.isSet ? (
            <div className="mt-4">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <Card.Title className="mb-0">
                            Bewegungsprogramm
                        </Card.Title>
                        <div className="ms-auto">
                            <Button onClick={this.addCommand} size="sm">
                                <FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={6}>
                                <ListGroup id="items">
                                    {store.commands.map(function (command, i) {
                                        return <Command key={store.commands[i].id} command={store.commands[i]}
                                                        onClickHandler={selectCommand}/>
                                    })}
                                </ListGroup>
                            </Col>
                            <Col xs={6}>
                                <CommandForm/>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        ) : (
            <div className="mt-2">
                <i>Bitte wählen Sie einen Roboter aus, um eine Bewegung vorzugeben.</i>
            </div>
        )
    }
}

export default easyComp(CommandsView);
