import React, {Component} from 'react';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import {FormControl, DropdownButton, Dropdown, InputGroup} from 'react-bootstrap';

class RobotFormJoint extends Component {
    render() {

        const { readonly } = this.props;
        let isRotate = this.props.i !== undefined && this.props.robotData && this.props.robotData.joints[this.props.i] && this.props.robotData.joints[this.props.i].h === 1;

        return (
            <tr>
                <td>
                    {(this.props.i + 1)}
                </td>
                <td>
                    <InputGroup size="sm">
                        <FormControl
                            type="text"
                            value={this.props.robotData.joints[this.props.i].theta}
                            onChange={this.props.jointNumberFieldChange}
                            name="theta"
                            jointkey={this.props.i}
                            readOnly={readonly}
                        />
                        <InputGroup.Text>deg</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup size="sm">
                        <FormControl
                            type="text"
                            value={this.props.robotData.joints[this.props.i].d}
                            onChange={this.props.jointNumberFieldChange}
                            name="d"
                            jointkey={this.props.i}
                            readOnly={readonly}
                        />
                        <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup size="sm">
                        <FormControl
                            type="text"
                            value={this.props.robotData.joints[this.props.i].a}
                            onChange={this.props.jointNumberFieldChange}
                            name="a"
                            jointkey={this.props.i}
                            readOnly={readonly}
                        />
                        <InputGroup.Text>m</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup size="sm">
                        <FormControl
                            type="text"
                            value={this.props.robotData.joints[this.props.i].alpha}
                            onChange={this.props.jointNumberFieldChange}
                            name="alpha"
                            jointkey={this.props.i}
                            readOnly={readonly}
                        />
                        <InputGroup.Text>deg</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup size="sm">
                        <FormControl
                            type="text"
                            value={this.props.robotData.joints[this.props.i].qmin}
                            onChange={this.props.jointNumberFieldChange}
                            name="qmin"
                            jointkey={this.props.i}
                            readOnly={readonly}
                        />
                        <InputGroup.Text>{isRotate ? 'deg' : 'm'}</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup size="sm">
                        <FormControl
                            type="text"
                            value={this.props.robotData.joints[this.props.i].qmax}
                            onChange={this.props.jointNumberFieldChange}
                            name="qmax"
                            jointkey={this.props.i}
                            readOnly={readonly}
                        />
                        <InputGroup.Text>{isRotate ? 'deg' : 'm'}</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup size="sm">
                        <FormControl
                            type="text"
                            value={this.props.robotData.joints[this.props.i].qdmax}
                            onChange={this.props.jointNumberFieldChange}
                            name="qdmax"
                            jointkey={this.props.i}
                            readOnly={readonly}
                        />
                        <InputGroup.Text>{isRotate ? 'deg/s' : 'm/s'}</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    <InputGroup size="sm">
                        <FormControl
                            type="text"
                            value={this.props.robotData.joints[this.props.i].qddmax}
                            onChange={this.props.jointNumberFieldChange}
                            name="qddmax"
                            jointkey={this.props.i}
                            readOnly={readonly}
                        />
                        <InputGroup.Text>{isRotate ? <span>deg/s<sup>2</sup></span> : <span>m/s<sup>2</sup></span>}</InputGroup.Text>
                    </InputGroup>
                </td>
                <td>
                    {readonly ? (
                        this.props.robotData.joints[this.props.i].h === 0 ? (
                            <span>Schubgelenk</span>
                        ) : (
                            <span>Drehgelenk</span>
                        )
                    ) : (
                        <DropdownButton
                            title={this.props.robotData.joints[this.props.i].h === 0 ? 'Schubgelenk' : (this.props.robotData.joints[this.props.i].h === 1 ? 'Drehgelenk' : '')}
                            onSelect={this.props.jointHChange}
                            id="jointH"
                            variant="outline-secondary"
                            size="sm"
                            drop="down" align="end"
                        >
                            <Dropdown.Item key={0} active={this.props.robotData.joints[this.props.i].h === 0}
                                      jointkey={this.props.i} eventKey={0}>
                                Schubgelenk
                            </Dropdown.Item>
                            <Dropdown.Item key={1} active={this.props.robotData.joints[this.props.i].h === 1}
                                      jointkey={this.props.i} eventKey={1}>
                                Drehgelenk
                            </Dropdown.Item>
                        </DropdownButton>
                    )}
                </td>
            </tr>
        )
    }
}

export default easyComp(RobotFormJoint);