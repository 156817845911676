import { Group, Vector3, ArrowHelper, CylinderGeometry, Mesh, MeshLambertMaterial } from 'three';

class CustomAxis extends Group {
    constructor(last) {
        super();

        if (last === true) {
            const directions = [
                new Vector3(1, 0, 0),
                new Vector3(0, 1, 0),
                new Vector3(0, 0, 1)
            ];
            const colors = [0xff0000, 0x00ff00, 0x0000ff];

            directions.forEach((dir, index) => {
                const arrow = new ArrowHelper(dir, new Vector3(), 0.5, colors[index], 0.05, 0.05);
                this.add(arrow);
            });
        } else {
            const jointGeo = new CylinderGeometry(0.075, 0.075, 0.075 * 2, 32, 32);
            const jointMesh = new Mesh(jointGeo, new MeshLambertMaterial({ color: 0x4d4d4d }));
            jointMesh.rotation.x = Math.PI / 2;
            this.add(jointMesh);
        }
    }
}

export default CustomAxis;