import { Mesh, BoxGeometry, MeshLambertMaterial } from 'three';

class CustomArm extends Mesh {
    constructor(length, jointNumber, tempTable) {
        const colors = [
            0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19,
            0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19, 0xf25c19
        ];

        let direction = "";
        let lengthX = 0.1;
        let lengthY = 0.1;
        let lengthZ = 0.1;

        if (tempTable.a === 0 && tempTable.d !== 0 && tempTable.alpha !== 0) {
            direction = "Y"
            lengthY = length
        } else if (tempTable.d === 0 && tempTable.a !== 0/* && tempTable.theta !==0*/) {
            direction = "X"
            lengthX = length
        } else if (parseFloat(Math.sin(tempTable.theta).toFixed(2)) === 0 && parseFloat(Math.sin(tempTable.alpha).toFixed(2)) === 0/* && tempTable.a === 0*/) {
            direction = "Z"
            lengthZ = length
            //}else if (tempTable.d != 0 && tempTable.a != 0 && tempTable.theta !=0){
        } else if (tempTable.d !== 0 && tempTable.a !== 0) {
            direction = "schraeggelenk"
            lengthZ = tempTable.d
        } else {
            direction = "Z"
            lengthZ = length
            //  console.log("Fehler bei der Zuordnung der initialen Extrusion")
        }

        const geometry = new BoxGeometry(lengthX, lengthY, lengthZ);
        const material = new MeshLambertMaterial({ color: colors[jointNumber % colors.length] });
        super(geometry, material);

        this.ExtrusionDirection = { direction };
    }
}

export default CustomArm;
