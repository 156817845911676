import { Points, BufferGeometry, BufferAttribute, PointsMaterial } from 'three';

class CustomTrack extends Points {
  constructor(waypoint, pointColor) {
    const geometry = new BufferGeometry();
    geometry.setAttribute('position', new BufferAttribute(waypoint, 3));
    const material = new PointsMaterial({ color: pointColor, size: 0.02 });
    super(geometry, material);
  }
}

export default CustomTrack;