import React, {Component} from 'react';
import {Row, Col, FormGroup, Form, FormControl} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from "../../../store";

class CommandWait extends Component {

    textChange(e) {
        this.props.command.data.wait = e.target.value;
        store.commandValueChanged();
    }

    render() {
        let command = this.props.command;

        return (
            <div>
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                            <Form.Label>
                                t <i>(s)</i>
                            </Form.Label>
                            <div>
                                <FormControl
                                    type="text"
                                    value={command.data.wait}
                                    onChange={this.textChange}
                                    name="wait"
                                />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default easyComp(CommandWait);
