import React, {Component} from 'react';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import {Modal} from 'react-bootstrap';
import store from '../../store.js';
import ThreeDRobot from "../robotAnimation/ThreeDRobot";
let animationId = null;

class RobotAnimationModal extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.state = {robotInstance: null};
    }

    componentDidMount() {
        requestAnimationFrame(() => {
            const canvas = this.canvasRef.current;
            if (!canvas) {
                console.error("Canvas not ready");
                return;
            }

            // Settings for the GUI
            const settings = {
                speed: 10,
                animate: true,
                gripper: true,
                bohrer: false,
                a: 0,
                d: 0,
                theta: 0,
                alpha: 0,
                waypoints: true,
                refpath: true,
            };

            const Q = store.translation.output.Q;
            const robot = JSON.parse(JSON.stringify(store.translation.input.robot));
            const table = robot.joints;

            const robotInstance = new ThreeDRobot(canvas, table, Q, robot.name, settings);
            this.setState({robotInstance});
        });
    }

    componentWillUnmount() {
        console.log('Unmounting RobotAnimationModal');

        if (animationId !== null) {
            cancelAnimationFrame(animationId);
            animationId = null;
        }

        if (this.state.robotInstance) {
            this.state.robotInstance.renderer.dispose();
        }

        window.removeEventListener('resize', this.handleResize);
    }

    onLoad = () => {

    }

    render() {
        return (
            <>
                <Modal.Header onHide={this.handleModalClose} closeButton>
                    <Modal.Title>3D Animation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="canvas" ref={this.canvasRef} style={{width: '100%', height: '70vh'}} onLoad={this.onLoad}>
                        <div id="gui"></div>
                    </div>
                </Modal.Body>
            </>
        );
    }
}

export default easyComp(RobotAnimationModal);
