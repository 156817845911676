import React, {Component} from 'react';
import {Row, Col, FormGroup, Form, FormControl} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';

class CommandComment extends Component {

    textChange(e) {
        this.props.command.data.text = e.target.value;
    }

    render() {
        let command = this.props.command;

        return (
            <div>
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <Form.Label>
                                Text
                            </Form.Label>
                            <div>
                                <FormControl
                                    type="text"
                                    value={command.data.text}
                                    onChange={this.textChange}
                                    name="text"
                                />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default easyComp(CommandComment);