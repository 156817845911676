import React, {Component} from 'react';
import {Row, Col, FormGroup, Form, DropdownButton, Dropdown, InputGroup, FormControl} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../../store.js';

class CommandFormTargetPose extends Component {

    targetChange(val) {
        let command = this.props.command;
        val = parseInt(val, 10);
        command.data.target = val;
        if (val === 1) {
            if(store.robot.data.preDefinedMask) {
                command.data.mask = store.robot.data.preDefinedMask;
            } else {
                command.data.mask = [false, false, false, false, false, false];
            }
            command.data.cartesianTargetPose = ['', '', '', '', '', ''];
            command.data.jointTargetPose = null;
        } else {
            command.data.mask = null;
            command.data.cartesianTargetPose = null;
            command.data.jointTargetPose = (new Array(store.robot.data.jointAmount)).fill('');
        }
        store.commandValueChanged();
    }

    velocityChange(val) {
        this.props.command.data.velocity = parseInt(val, 10);
        store.commandValueChanged();
    }

    maskChange(e) {
        let checked = e.target.checked;
        let dataindex = e.target.getAttribute('dataindex');
        this.props.command.data.mask[dataindex] = checked;
        if (!checked) {
            this.props.command.data.cartesianTargetPose[dataindex] = '';
        }
        store.commandValueChanged();
    }

    ctpChange(e) {
        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            this.props.command.data.cartesianTargetPose[e.target.getAttribute('dataindex')] = e.target.value;
            store.commandValueChanged();
        }
    }

    jtpChange(e) {
        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            this.props.command.data.jointTargetPose[e.target.getAttribute('dataindex')] = e.target.value;
            store.commandValueChanged();
        }
    }

    render() {
        let command = this.props.command;
        let cartesianData = ['x', 'y', 'z', 'A', 'B', 'C'];
        let {maskChange, ctpChange, jtpChange} = this;

        return (
            <div>
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <Form.Label>
                                target
                            </Form.Label>
                            <div>
                                <DropdownButton
                                    title={command.data.target === 1 ? 'cartesian' : 'joint'}
                                    id='selectTarget'
                                    onSelect={this.targetChange}
                                >
                                    <Dropdown.Item eventKey="1">cartesian</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">joint</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <Form.Label>
                                motion profile
                            </Form.Label>
                            <div>
                                <DropdownButton
                                    title={command.data.velocity === 1 ? 'ramp' : 'sinoid'}
                                    id='selectVelocity'
                                    onSelect={this.velocityChange}
                                >
                                    <Dropdown.Item eventKey="1">ramp</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">sinoid</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <div className="mt-3">
                    {command.data.target === 1 ? (
                      <Row>
                          {cartesianData.map(function (label, i) {
                              if(!store.robot.data.preDefinedMask || store.robot.data.preDefinedMask[i] === true) {
                                  return (
                                    <Col xs={4} key={i} className="mb-2">
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    {command.type.includes('REL') ? (
                                                      <span>&Delta;</span>
                                                    ) : ''}
                                                    {label}
                                                    {['x', 'y', 'z'].includes(label) ? (
                                                      <sub>0</sub>
                                                    ) : ''}
                                                </InputGroup.Text>
                                                {!store.robot.data.preDefinedMask ? (
                                                  <InputGroup.Text>
                                                      <input type="checkbox" dataindex={i} onChange={maskChange}
                                                             checked={command.data.mask[i]}
                                                             disabled={command.data.mask[i] !== true && command.data.mask.filter(x => x === true).length >= store.robot.data.jointAmount}/>
                                                  </InputGroup.Text>
                                                ) : null }
                                                <FormControl type="text" dataindex={i} onChange={ctpChange}
                                                             value={command.data.cartesianTargetPose[i]}
                                                             disabled={!command.data.mask[i]}/>
                                                <InputGroup.Text>{['x', 'y', 'z'].includes(label) ? 'm' : 'deg'}</InputGroup.Text>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                  )
                              }
                          })}
                      </Row>
                    ) : (
                      <Row>
                          {[...Array(store.robot.data.jointAmount).keys()].map(function (x) {
                              let isRotate =  store.robot.data && store.robot.data.joints[x] && store.robot.data.joints[x].h === 1;
                              return (
                                <Col xs={4} key={x} className="mb-2">
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                {command.type.includes('REL') ? (
                                                  <span>&Delta;</span>
                                                ) : ''}
                                                q<sub>{(x + 1)}</sub>
                                            </InputGroup.Text>
                                            <FormControl type="text" dataindex={x} onChange={jtpChange}
                                                         value={command.data.jointTargetPose[x]}/>
                                            <InputGroup.Text>{isRotate ? 'deg' : 'm'}</InputGroup.Text>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                              )
                          })}
                      </Row>
                    )}
                </div>
            </div>
        )
    }
}

export default easyComp(CommandFormTargetPose);
