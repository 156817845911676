import {evaluate, squeeze} from "mathjs";
import R6RTrafo from "./R6RTrafo";
let math = require('mathjs');

class IkinPtpR6 {
    run(F, q, r, mask, tIpo) {
        let r6RTrafo = new R6RTrafo();
        let eps_q = 0.0001;
        let q_alt = q;
        let qs = [];
        let qloes = [];

        let N = r.joints.length;
        let hh = r.joints.map(j => j.h);

        let qmax = r.joints.map(j => j.qmax);
        let qmin = r.joints.map(j => j.qmin);

        let zahlposen = 1;
        let anz = 0;

        let F_akt = F;

        let qz = r6RTrafo.run(F_akt, q_alt, r);

        for(let iz = 1; iz <= 8; iz++) {
            let q = evaluate('squeeze(qz[:,iz])', {qz, iz});

            // begin "out of range"
            for(let ig = 0; ig < N; ig++) {
                if(hh[ig] === 1) {
                    q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - math.fix(math.subset(q, math.index(ig)) / (2 * Math.PI + eps_q)) * 2 * Math.PI);
                    if(math.subset(qmin, math.index(ig)) >= 0 && math.subset(qmax, math.index(ig)) > 0 && math.subset(q, math.index(ig)) < 0) {
                        q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) + 2 * Math.PI);
                    }
                    if(math.subset(qmin, math.index(ig)) < 0 && math.subset(qmax, math.index(ig)) >= 0) {
                        if(math.subset(q, math.index(ig)) > math.subset(qmax, math.index(ig))) {
                            q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - 2 * Math.PI);
                        }
                        if(math.subset(q, math.index(ig)) < math.subset(qmin, math.index(ig))) {
                            q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) + 2 * Math.PI);
                        }
                    }
                    if(math.subset(qmin, math.index(ig)) < 0 && math.subset(qmax, math.index(ig)) <= 0 && math.subset(q, math.index(ig)) > 0) {
                        q = math.subset(q, math.index(ig), math.subset(q, math.index(ig)) - 2 * Math.PI);
                    }
                }
            }
            let out_range = 0;
            for(let k = 0; k < N; k++) {
                if(q[k] > (qmax[k] + eps_q) || q[k] < (qmin[k] - eps_q)) {
                    out_range = 1;
                }
            }
            // end "out of range"

            if(out_range === 0) {
                anz = anz + 1;
                qloes.push(squeeze(q)._data);
            }
        }
        //console.log('qloes', qloes);
        if(anz > 0) {
            let abw = [];
            for(let i1 = 0; i1 < anz; i1++) {
                //console.log(q_alt, qloes);
                abw.push(math.norm(math.subtract(q_alt, qloes[i1])));
                //abw = abw.subset(math.index(i1-1), evaluate('norm(q_alt-squeeze(qloes[:,i1]))', {q_alt: squeeze(q_alt), qloes, i1}));
            }
            let min_loes = abw.indexOf(Math.min.apply(null, abw.filter(a => a.isComplex !== true)));
            //console.log('abw, min_loes', abw, min_loes);
            //q = qloes[min_loes]
            //q = evaluate('qloes[:,min_loes]', {qloes, min_loes});
            //qs = evaluate('[qs q]', {qs, q});
            qs = qloes[min_loes];
            //q_alt = q;
        }

        //console.log('qs', qs);

        return qs;
    }
}

export default IkinPtpR6;