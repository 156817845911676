import Sgn from "./Sgn";
import {evaluate, squeeze} from "mathjs";
import DownTo0 from "./DownTo0";

let math = require('mathjs');

class DiffCartesian {
    run(T1, T2) {
        let sgn = new Sgn();
        let downTo0 = new DownTo0();
        let diff = math.ones(6, 1);

        diff = math.subset(diff, math.index([0, 1, 2], 0), math.subtract(math.subset(T2, math.index([0, 1, 2], 3)), math.subset(T1, math.index([0, 1, 2], 3))));

        let A20 = math.subset(T2, math.index([...Array(T2.size()[0]).keys()], [0, 1, 2]));
        let A10 = math.subset(T1, math.index([0, 1, 2], [0, 1, 2]));
        let A21 = math.multiply(math.transpose(A10), A20);
        let A21_A = evaluate('A21[:,1]', {A21});
        A21_A = evaluate('A21_A / norm(A21_A)', {A21_A: squeeze(A21_A)});
        let A21_B = evaluate('A21[:,2]', {A21});
        A21_B = evaluate('A21_B / norm(A21_B)', {A21_B: squeeze(A21_B)});
        let A21_C = evaluate('A21[:,3]', {A21});
        A21_C = evaluate('A21_C / norm(A21_C)', {A21_C: squeeze(A21_C)});
        A21 = evaluate('transpose([A21_A, A21_B, A21_C])', {A21_A, A21_B, A21_C});

        let quat = [];
        quat[0] = 0.5 * math.sqrt(math.subset(A21, math.index(0, 0)) + math.subset(A21, math.index(1, 1)) + math.subset(A21, math.index(2, 2)) + 1);
        quat[1] = 0.5 * sgn.run(math.subset(A21, math.index(2, 1)) - math.subset(A21, math.index(1, 2))) * math.sqrt(math.subset(A21, math.index(0, 0)) - math.subset(A21, math.index(1, 1)) - math.subset(A21, math.index(2, 2)) + 1);
        quat[2] = 0.5 * sgn.run(math.subset(A21, math.index(0, 2)) - math.subset(A21, math.index(2, 0))) * math.sqrt(downTo0.run(math.subset(A21, math.index(1, 1)) - math.subset(A21, math.index(2, 2)) - math.subset(A21, math.index(0, 0)) + 1));
        quat[3] = 0.5 * sgn.run(math.subset(A21, math.index(1, 0)) - math.subset(A21, math.index(0, 1))) * math.sqrt(math.subset(A21, math.index(2, 2)) - math.subset(A21, math.index(0, 0)) - math.subset(A21, math.index(1, 1)) + 1);

        let eps = [quat[1], quat[2], quat[3]];
        let drehw = 2* math.acos(quat[0]);

        if(math.abs(drehw) > 0.001) {
            let r_vek_K_akt = math.divide(eps, math.sin(drehw/2));
            let r_vek = math.multiply(A10, r_vek_K_akt);
            diff = math.subset(diff, math.index([3, 4, 5], 0), math.reshape(math.multiply(r_vek, drehw), [3, 1]));
        } else {
            diff = math.subset(diff, math.index([3, 4, 5], 0), math.zeros(3, 1));
        }

        return math.squeeze(diff);
    }
}

export default DiffCartesian;