import {evaluate, squeeze} from 'mathjs';
import Fkin from "./Fkin";

class R6RTrafo {
  run(F_akt, q_alt, r) {
    let fkin = new Fkin();

    let p_06 = squeeze(evaluate('F_akt[:,4]', {F_akt: squeeze(F_akt)}));

    let A60 = evaluate('F_akt[:,1:3]', {F_akt: squeeze(F_akt)});
    let ltcp = evaluate('A60[:,1]', {A60});
    ltcp = evaluate('ltcp / norm(ltcp)', {ltcp: squeeze(ltcp)});
    let mtcp = evaluate('A60[:,2]', {A60});
    mtcp = evaluate('mtcp / norm(mtcp)', {mtcp: squeeze(mtcp)});
    let ntcp = evaluate('A60[:,3]', {A60});
    ntcp = evaluate('ntcp / norm(ntcp)', {ntcp: squeeze(ntcp)});

    let qalt = q_alt;

    let d = r.joints.map(j => j.d);
    let a = r.joints.map(j => j.a);
    let alpha = r.joints.map(j => j.alpha);

    let qmax = r.joints.map(j => j.qmax);
    let qmin = r.joints.map(j => j.qmin);

    let eps_q5 = 1e-4;

    let warnung = '';
    let q = [];
    let qz;

    let p04 = evaluate('p_06 - ntcp * d[6]', {p_06: squeeze(p_06), ntcp: squeeze(ntcp), d});

    let q1a = evaluate('atan2(p04[2], p04[1])', {p04});
    let q1b;

    if (q1a >= 0) {
      q1b = evaluate('q1a - pi', {q1a});
    } else {
      q1b = evaluate('q1a + pi', {q1a});
    }

    let p14a = evaluate('p04 - squeeze([a[1]*cos(q1a); a[1]*sin(q1a); d[1]])', {p04: squeeze(p04), a, q1a: squeeze(q1a), d});
    let p14b = evaluate('p04 - squeeze([a[1]*cos(q1b); a[1]*sin(q1b); d[1]])', {p04: squeeze(p04), a, q1b: squeeze(q1b), d});

    let betr_quad_p14 = evaluate("p14a' * p14a", {p14a});
    let AR_test_aussen = evaluate('(a[2] + d[4])^2 - betr_quad_p14', {a, d, betr_quad_p14});

    let AR_test_innen;
    if (d[4] < a[2]) {
      AR_test_innen = evaluate('betr_quad_p14 - (a[2] - d[4])^2', {betr_quad_p14, a, d});
    } else {
      AR_test_innen = 25;
    }

    if (AR_test_aussen >= 0 && AR_test_innen >= 0) {
      let phia = evaluate('acos((a[2]^2 + d[4]^2 - betr_quad_p14)/(2 * a[2] * d[4]))', {a, d, betr_quad_p14});
      let q_akt = evaluate('[q1a; 0; 0; 0; 0; 0]', {q1a});
      let T_T0 = fkin.run(r, q_akt);
      let T10a = evaluate('T[:,:,1]', {T: T_T0[0]});
      let A10a = evaluate('T10a[1:3,1:3]', {T10a: squeeze(T10a)});
      let A01a = evaluate("A10a'", {A10a});
      let p141a = evaluate('A01a * p14a', {A01a, p14a});
      let beta1 = evaluate('atan2(p141a[2], p141a[1])', {p141a});
      let beta2 = evaluate('acos((a[2]^2 + betr_quad_p14 - d[4]^2) / (2*sqrt(betr_quad_p14) * a[2]))', {a, betr_quad_p14, d});

      let q3a = 1.5 * Math.PI - phia;
      let q3b = 1.5 * Math.PI + phia;
      let q2a = beta1 - beta2;
      let q2b = beta1 + beta2;

      let T, T0;
      
      q_akt = evaluate('[q1a; q2a; q3a; 0; 0; 0]', {q1a, q2a, q3a});
      let fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T30a = squeeze(evaluate('T0[:,:,3]', {T0}));
      let x3a = squeeze(evaluate('T30a[1:3,1]', {T30a}));
      x3a = evaluate('x3a/norm(x3a)', {x3a});
      let y3a = squeeze(evaluate('T30a[1:3,2]', {T30a}));
      y3a = evaluate('y3a/norm(y3a)', {y3a});
      let z3a = squeeze(evaluate('T30a[1:3,3]', {T30a}));
      z3a = evaluate('z3a/norm(z3a)', {z3a});

      q_akt = evaluate('[q1a; q2b; q3b; 0; 0; 0]', {q1a, q2b, q3b});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T30b = squeeze(evaluate('T0[:,:,3]', {T0}));
      let x3b = squeeze(evaluate('T30b[1:3,1]', {T30b}));
      x3b = evaluate('x3b/norm(x3b)', {x3b});
      let y3b = squeeze(evaluate('T30b[1:3,2]', {T30b}));
      y3b = evaluate('y3b/norm(y3b)', {y3b});
      let z3b = squeeze(evaluate('T30b[1:3,3]', {T30b}));
      z3b = evaluate('z3b/norm(z3b)', {z3b});

      let q5_betrag_a = evaluate("abs(acos(z3a'*ntcp))", {z3a, ntcp});
      let q5_betrag_b = evaluate("abs(acos(z3b'*ntcp))", {z3b, ntcp});

      let q4a, q4b;
      if (q5_betrag_a < eps_q5) {
        q4a = squeeze(evaluate("qalt[4]", {qalt}));
        q4b = squeeze(evaluate("qalt[4]", {qalt}));
      } else {
        let cvek1 = evaluate("cross(z3a, ntcp)", {z3a, ntcp});
        let cvek = evaluate('cvek1/norm(cvek1)', {cvek1});
        let gamma = evaluate("acos(x3a'*cvek)", {x3a, cvek});

        let Vorz;
        if (gamma < Math.PI/2) {
          Vorz = -1;
        } else {
          Vorz = 1;
        }
        q4a = evaluate("Vorz*acos(y3a'*cvek)", {Vorz, y3a, cvek});
        q4b = q4a + Math.Pi;
      }

      let q4c, q4d;
      if (q5_betrag_b < eps_q5) {
        q4c = squeeze(evaluate("qalt[4]", {qalt}));
        q4d = squeeze(evaluate("qalt[4]", {qalt}));
      } else {
        let cvek1 = evaluate("cross(z3b, ntcp)", {z3b, ntcp});
        let cvek = evaluate('cvek1/norm(cvek1)', {cvek1});
        let gamma = evaluate("acos(x3b'*cvek)", {x3b, cvek});

        let Vorz;
        if (gamma < Math.PI/2) {
          Vorz = -1;
        } else {
          Vorz = 1;
        }
        q4c = evaluate("Vorz*acos(y3b'*cvek)", {Vorz, y3b, cvek});
        q4d = q4c + Math.Pi;
      }

      eps_q5 = 0.0001;
      q_akt = evaluate('[q1a; q2a; q3a; q4a; 0; 0]', {q1a, q2a, q3a, q4a});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T40a = squeeze(evaluate("T0[:,:,4]", {T0}));
      let A40a = evaluate("T40a[1:3, 1:3]", {T40a});
      let A04a = evaluate("A40a'", {A40a});
      let z5_vek = evaluate("A04a*(p_06-p04)/d[6]", {A04a, p_06, p04, d});
      let betr_q5a = evaluate("asin(z5_vek[2]) + pi/2", {z5_vek});
      q_akt = evaluate('[q1a; q2a; q3a; q4a; betr_q5a; 0]', {q1a, q2a, q3a, q4a, betr_q5a});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let q5a;
      if (evaluate("norm(p_06 - squeeze(T0[1:3,4,6]))", {p_06, T0}) < eps_q5) {
        q5a = betr_q5a;
      } else {
        q5a = betr_q5a * -1;
      }


      q_akt = evaluate('[q1a; q2a; q3a; q4b; 0; 0]', {q1a, q2a, q3a, q4b});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T40b = squeeze(evaluate("T0[:,:,4]", {T0}));
      let A40b = evaluate("T40b[1:3, 1:3]", {T40b});
      let A04b = evaluate("A40b'", {A40b});
      z5_vek = evaluate("A04b*(p_06-p04)/squeeze(d[6])", {A04b, p_06, p04, d});
      let betr_q5b = evaluate("asin(z5_vek[2]) + pi/2", {z5_vek});
      q_akt = evaluate('[q1a; q2a; q3a; q4b; betr_q5b; 0]', {q1a, q2a, q3a, q4b, betr_q5b});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let q5b;
      if (evaluate("norm(p_06-squeeze(T0[1:3,4,6]))", {p_06, T0}) < eps_q5) {
        q5b = betr_q5b;
      } else {
        q5b = betr_q5b * -1;
      }


      q_akt = evaluate('[q1a; q2b; q3b; q4c; 0; 0]', {q1a, q2b, q3b, q4c});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T40c = squeeze(evaluate("T0[:,:,4]", {T0}));
      let A40c = evaluate("T40c[1:3, 1:3]", {T40c});
      let A04c = evaluate("A40c'", {A40c});
      z5_vek = evaluate("A04c*(p_06-p04)/squeeze(d[6])", {A04c, p_06, p04, d});
      let betr_q5c = evaluate("asin(z5_vek[2]) + pi/2", {z5_vek});
      q_akt = evaluate('[q1a; q2b; q3b; q4c; betr_q5c; 0]', {q1a, q2b, q3b, q4c, betr_q5c});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let q5c;
      if (evaluate("norm(p_06-squeeze(T0[1:3,4,6]))", {p_06, T0}) < eps_q5) {
        q5c = betr_q5c;
      } else {
        q5c = betr_q5c * -1;
      }


      q_akt = evaluate('[q1a; q2b; q3b; q4d; 0; 0]', {q1a, q2b, q3b, q4d});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T40d = squeeze(evaluate("T0[:,:,4]", {T0}));
      let A40d = evaluate("T40d[1:3, 1:3]", {T40d});
      let A04d = evaluate("A40d'", {A40d});
      z5_vek = evaluate("A04d*(p_06-p04)/squeeze(d[6])", {A04d, p_06, p04, d});
      let betr_q5d = evaluate("asin(z5_vek[2]) + pi/2", {z5_vek});
      q_akt = evaluate('[q1a; q2b; q3b; q4d; betr_q5d; 0]', {q1a, q2b, q3b, q4d, betr_q5d});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let q5d;
      if (evaluate("norm(p_06-squeeze(T0[1:3,4,6]))", {p_06, T0}) < eps_q5) {
        q5d = betr_q5d;
      } else {
        q5d = betr_q5d * -1;
      }




      q_akt = evaluate('[q1a; q2a; q3a; q4a; q5a; 0]', {q1a, q2a, q3a, q4a, q5a});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T50a = squeeze(evaluate('T0[:,:,5]', {T0}));

      q_akt = evaluate('[q1a; q2a; q3a; q4b; q5b; 0]', {q1a, q2a, q3a, q4b, q5b, q5a});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T50b = squeeze(evaluate('T0[:,:,5]', {T0}));

      q_akt = evaluate('[q1a; q2b; q3b; q4c; q5c; 0]', {q1a, q2b, q3b, q4c, q5c});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T50c = squeeze(evaluate('T0[:,:,5]', {T0}));

      q_akt = evaluate('[q1a; q2b; q3b; q4d; q5d; 0]', {q1a, q2b, q3b, q4d, q5d});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T50d = squeeze(evaluate('T0[:,:,5]', {T0}));

      let x5a = squeeze(evaluate('T50a[1:3,1]', {T50a}));
      x5a = evaluate('x5a/norm(x5a)', {x5a});
      let x5b = squeeze(evaluate('T50b[1:3,1]', {T50b}));
      x5b = evaluate('x5b/norm(x5b)', {x5b});
      let x5c = squeeze(evaluate('T50c[1:3,1]', {T50c}));
      x5c = evaluate('x5c/norm(x5c)', {x5c});
      let x5d = squeeze(evaluate('T50d[1:3,1]', {T50d}));
      x5d = evaluate('x5d/norm(x5d)', {x5d});
      let y5a = squeeze(evaluate('T50a[1:3,2]', {T50a}));
      y5a = evaluate('y5a/norm(y5a)', {y5a});
      let y5b = squeeze(evaluate('T50b[1:3,2]', {T50b}));
      y5b = evaluate('y5b/norm(y5b)', {y5b});
      let y5c = squeeze(evaluate('T50c[1:3,2]', {T50c}));
      y5c = evaluate('y5c/norm(y5c)', {y5c});
      let y5d = squeeze(evaluate('T50d[1:3,2]', {T50d}));
      y5d = evaluate('y5d/norm(y5d)', {y5d});

      let gamma = evaluate("acos(y5a'*ltcp)", {y5a, ltcp});
      let Vorz;
      if (gamma > Math.PI / 2) {
        Vorz = -1;
      } else {
        Vorz = 1;
      }
      let q6st = evaluate("Vorz * acos(y5a'*mtcp)", {Vorz, y5a, mtcp});
      let tresults = [evaluate('abs(qalt[6]-q6st+2*pi)', {qalt, q6st}), evaluate('abs(qalt[6]-q6st)', {
        qalt,
        q6st
      }), evaluate('abs(qalt[6]-q6st-2*pi)', {qalt, q6st})];
      let jtest = tresults.indexOf(Math.min.apply(Math, tresults)) + 1;
      let q6a = evaluate('q6st+(2*jtest-4)*pi', {q6st, jtest});

      gamma = evaluate("acos(y5b'*ltcp)", {y5b, ltcp});
      if (gamma > Math.PI / 2) {
        Vorz = -1;
      } else {
        Vorz = 1;
      }
      q6st = evaluate("Vorz * acos(y5b'*mtcp)", {Vorz, y5b, mtcp});
      tresults = [evaluate('abs(qalt[6]-q6st+2*pi)', {qalt, q6st}), evaluate('abs(qalt[6]-q6st)', {
        qalt,
        q6st
      }), evaluate('abs(qalt[6]-q6st-2*pi)', {qalt, q6st})];
      jtest = tresults.indexOf(Math.min.apply(Math, tresults)) + 1;
      let q6b = evaluate('q6st+(2*jtest-4)*pi', {q6st, jtest});

      gamma = evaluate("acos(y5c'*ltcp)", {y5c, ltcp});
      if (gamma > Math.PI / 2) {
        Vorz = -1;
      } else {
        Vorz = 1;
      }
      q6st = evaluate("Vorz * acos(y5c'*mtcp)", {Vorz, y5c, mtcp});
      tresults = [evaluate('abs(qalt[6]-q6st+2*pi)', {qalt, q6st}), evaluate('abs(qalt[6]-q6st)', {
        qalt,
        q6st
      }), evaluate('abs(qalt[6]-q6st-2*pi)', {qalt, q6st})];
      jtest = tresults.indexOf(Math.min.apply(Math, tresults)) + 1;
      let q6c = evaluate('q6st+(2*jtest-4)*pi', {q6st, jtest});

      gamma = evaluate("acos(y5d'*ltcp)", {y5d, ltcp});
      if (gamma > Math.PI / 2) {
        Vorz = -1;
      } else {
        Vorz = 1;
      }
      q6st = evaluate("Vorz * acos(y5d'*mtcp)", {Vorz, y5d, mtcp});
      tresults = [evaluate('abs(qalt[6]-q6st+2*pi)', {qalt, q6st}), evaluate('abs(qalt[6]-q6st)', {
        qalt,
        q6st
      }), evaluate('abs(qalt[6]-q6st-2*pi)', {qalt, q6st})];
      jtest = tresults.indexOf(Math.min.apply(Math, tresults)) + 1;
      let q6d = evaluate('q6st+(2*jtest-4)*pi', {q6st, jtest});






      let phib = evaluate("acos((a[2]^2+d[4]^2-betr_quad_p14)/(2*a[2]*d[4]))", {a, d, betr_quad_p14});

      q_akt = evaluate('[q1b; 0; 0; 0; 0; 0]', {q1b});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T10b = squeeze(evaluate('T[:,:,1]', {T}));
      let A10b = evaluate('T10b[1:3,1:3]', {T10b});
      let A01b = evaluate("A10b'", {A10b});
      let p141b = evaluate('A01b*p14b', {A01b, p14b});
      beta1 = evaluate('atan2(p141b[2], p141b[1])', {p141b});
      beta2 = evaluate('acos((a[2]^2+betr_quad_p14-d[4]^2)/(2*sqrt(betr_quad_p14)*a[2]))', {a, betr_quad_p14, d});

      let q3c = 1.5 * Math.PI - phib;
      let q3d = 1.5 * Math.PI + phib;
      let q2c = beta1 - beta2;
      let q2d = beta1 + beta2;


      q_akt = evaluate('[q1b; q2c; q3c; 0; 0; 0]', {q1b, q2c, q3c});

      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];

      let T30_c = squeeze(evaluate('T0[:,:,3]', {T0}));
      let x3c = squeeze(evaluate('T30_c[1:3,1]', {T30_c}));
      x3c = evaluate('x3c/norm(x3c)', {x3c});
      let y3c = squeeze(evaluate('T30_c[1:3,2]', {T30_c}));
      y3c = evaluate('y3c/norm(y3c)', {y3c});
      let z3c = squeeze(evaluate('T30_c[1:3,3]', {T30_c}));
      z3c = evaluate('z3c/norm(z3c)', {z3c});

      q_akt = evaluate('[q1b; q2d; q3d; 0; 0; 0]', {q1b, q2d, q3d});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];

      let T30_d = squeeze(evaluate('T0[:,:,3]', {T0}));
      let x3d = squeeze(evaluate('T30_d[1:3,1]', {T30_d}));
      x3d = evaluate('x3d/norm(x3d)', {x3d});
      let y3d = squeeze(evaluate('T30_d[1:3,2]', {T30_d}));
      y3d = evaluate('y3d/norm(y3d)', {y3d});
      let z3d = squeeze(evaluate('T30_d[1:3,3]', {T30_d}));
      z3d = evaluate('z3d/norm(z3d)', {z3d});

      q5_betrag_a = evaluate("abs(acos(z3c'*ntcp))", {z3c, ntcp});
      q5_betrag_b = evaluate("abs(acos(z3d'*ntcp))", {z3d, ntcp});


      let q4e, q4f;
      if (evaluate('q5_betrag_a < eps_q5', {q5_betrag_a, eps_q5})) {
        q4e = evaluate('qalt[4]', {qalt});
        q4f = evaluate('qalt[4]', {qalt});
      } else {
        let cvek1 = evaluate('cross(z3c, ntcp)', {z3c, ntcp});
        let cvek = evaluate("cvek1/norm(cvek1)", {cvek1});
        gamma = evaluate("acos(x3c'*cvek)", {x3c, cvek});

        if (gamma < (Math.PI / 2)) {
          Vorz = -1;
        } else {
          Vorz = 1;
        }

        q4e = evaluate("Vorz*acos(y3c'*cvek)", {Vorz, y3c, cvek});
        q4f = evaluate("q4e+pi", {q4e});
      }

      let q4g, q4h;
      if (evaluate('q5_betrag_b < eps_q5', {q5_betrag_b, eps_q5})) {
        q4g = evaluate('qalt[4]', {qalt});
        q4h = evaluate('qalt[4]', {qalt});
      } else {
        let cvek1 = evaluate('cross(z3d, ntcp)', {z3d, ntcp});
        let cvek = evaluate("cvek1/norm(cvek1)", {cvek1});
        gamma = evaluate("acos(x3d'*cvek)", {x3d, cvek});

        if (gamma <= Math.PI / 2) {
          Vorz = -1;
        } else {
          Vorz = 1;
        }

        q4g = evaluate("Vorz*acos(y3d'*cvek)", {Vorz, y3d, cvek});
        q4h = evaluate("q4g+pi", {q4g});
      }



      q_akt = evaluate('[q1b; q2c; q3c; q4e; 0; 0]', {q1b, q2c, q3c, q4e});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      T40a = squeeze(evaluate("T0[:,:,4]", {T0}));
      A40a = evaluate("T40a[1:3, 1:3]", {T40a});
      A04a = evaluate("A40a'", {A40a});
      z5_vek = evaluate("A04a*(p_06-p04)/squeeze(d[6])", {A04a, p_06, p04, d});
      let betr_q5e = evaluate("asin(z5_vek[2]) + pi/2", {z5_vek});
      q_akt = evaluate('[q1b; q2c; q3c; q4e; betr_q5e; 0]', {q1b, q2c, q3c, q4e, betr_q5e});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let q5e;
      if (evaluate("norm(p_06-squeeze(T0[1:3,4,6]))", {p_06, T0}) < eps_q5) {
        q5e = betr_q5e;
      } else {
        q5e = betr_q5e * -1;
      }


      q_akt = evaluate('[q1b; q2c; q3c; q4f; 0; 0]', {q1b, q2c, q3c, q4f});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      T40b = squeeze(evaluate("T0[:,:,4]", {T0}));
      A40b = evaluate("T40b[1:3, 1:3]", {T40b});
      A04b = evaluate("A40b'", {A40b});
      z5_vek = evaluate("A04b*(p_06-p04)/squeeze(d[6])", {A04b, p_06, p04, d});
      let betr_q5f = evaluate("asin(z5_vek[2]) + pi/2", {z5_vek});
      q_akt = evaluate('[q1b; q2c; q3c; q4f; betr_q5f; 0]', {q1b, q2c, q3c, q4f, betr_q5f});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let q5f;
      if (evaluate("norm(p_06-squeeze(T0[1:3,4,6]))", {p_06, T0}) < eps_q5) {
        q5f = betr_q5f;
      } else {
        q5f = betr_q5f * -1;
      }


      q_akt = evaluate('[q1b; q2d; q3d; q4g; 0; 0]', {q1b, q2d, q3d, q4g});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      T40c = squeeze(evaluate("T0[:,:,4]", {T0}));
      A40c = evaluate("T40c[1:3, 1:3]", {T40c});
      A04c = evaluate("A40c'", {A40c});
      z5_vek = evaluate("A04c*(p_06-p04)/squeeze(d[6])", {A04c, p_06, p04, d});
      let betr_q5g = evaluate("asin(z5_vek[2]) + pi/2", {z5_vek});
      q_akt = evaluate('[q1b; q2d; q3d; q4g; betr_q5g; 0]', {q1b, q2d, q3d, q4g, betr_q5g});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let q5g;
      if (evaluate("norm(p_06-squeeze(T0[1:3,4,6]))", {p_06, T0}) < eps_q5) {
        q5g = betr_q5g;
      } else {
        q5g = betr_q5g * -1;
      }


      q_akt = evaluate('[q1b; q2d; q3d; q4h; 0; 0]', {q1b, q2d, q3d, q4h});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      T40d = squeeze(evaluate("T0[:,:,4]", {T0}));
      A40d = evaluate("T40d[1:3, 1:3]", {T40d});
      A04d = evaluate("A40d'", {A40d});
      z5_vek = evaluate("A04d*(p_06-p04)/squeeze(d[6])", {A04d, p_06, p04, d});
      let betr_q5h = evaluate("asin(z5_vek[2]) + pi/2", {z5_vek});
      q_akt = evaluate('[q1b; q2d; q3d; q4h; betr_q5h; 0]', {q1b, q2d, q3d, q4h, betr_q5h});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let q5h;
      if (evaluate("norm(p_06-squeeze(T0[1:3,4,6]))", {p_06, T0}) < eps_q5) {
        q5h = betr_q5h;
      } else {
        q5h = betr_q5h * -1;
      }




      q_akt = evaluate('[q1b; q2c; q3c; q4e; q5e; 0]', {q1b, q2c, q3c, q4e, q5e});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T50e = squeeze(evaluate('T0[:,:,5]', {T0}));

      q_akt = evaluate('[q1b; q2c; q3c; q4f; q5f; 0]', {q1b, q2c, q3c, q4f, q5f});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T50f = squeeze(evaluate('T0[:,:,5]', {T0}));

      q_akt = evaluate('[q1b; q2d; q3d; q4g; q5g; 0]', {q1b, q2d, q3d, q4g, q5g});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T50g = squeeze(evaluate('T0[:,:,5]', {T0}));

      q_akt = evaluate('[q1b; q2d; q3d; q4h; q5h; 0]', {q1b, q2d, q3d, q4h, q5h});
      fKinTmp = fkin.run(r, q_akt);
      T = fKinTmp[0];
      T0 = fKinTmp[1];
      let T50h = squeeze(evaluate('T0[:,:,5]', {T0}));

      /*let x5e = evaluate('T50e[1:3,1]', {T50e});
      x5e = evaluate('x5e/norm(x5e)', {x5e});
      let x5f = evaluate('T50f[1:3,1]', {T50f});
      x5f = evaluate('x5f/norm(x5f)', {x5f});
      let x5g = evaluate('T50g[1:3,1]', {T50g});
      x5g = evaluate('x5g/norm(x5g)', {x5g});
      let x5h = evaluate('T50h[1:3,1]', {T50h});
      x5h = evaluate('x5h/norm(x5h)', {x5h});*/
      let y5e = squeeze(evaluate('T50e[1:3,2]', {T50e}));
      y5e = evaluate('y5e/norm(y5e)', {y5e});
      let y5f = squeeze(evaluate('T50f[1:3,2]', {T50f}));
      y5f = evaluate('y5f/norm(y5f)', {y5f});
      let y5g = squeeze(evaluate('T50g[1:3,2]', {T50g}));
      y5g = evaluate('y5g/norm(y5g)', {y5g});
      let y5h = squeeze(evaluate('T50h[1:3,2]', {T50h}));
      y5h = evaluate('y5h/norm(y5h)', {y5h});

      gamma = evaluate("acos(y5e'*ltcp)", {y5e, ltcp});
      if (gamma > Math.PI / 2) {
        Vorz = -1;
      } else {
        Vorz = 1;
      }
      q6st = evaluate("Vorz*acos(y5e'*mtcp)", {Vorz, y5e, mtcp});
      tresults = [evaluate('abs(qalt[6]-q6st+2*pi)', {qalt, q6st}), evaluate('abs(qalt[6]-q6st)', {
        qalt,
        q6st
      }), evaluate('abs(qalt[6]-q6st-2*pi)', {qalt, q6st})];
      jtest = tresults.indexOf(Math.min.apply(Math, tresults)) + 1;
      let q6e = evaluate('q6st+(2*jtest-4)*pi', {q6st, jtest});

      gamma = evaluate("acos(y5f'*ltcp)", {y5f, ltcp});
      if (gamma > Math.PI / 2) {
        Vorz = -1;
      } else {
        Vorz = 1;
      }
      q6st = evaluate("Vorz*acos(y5f'*mtcp)", {Vorz, y5f, mtcp});
      tresults = [evaluate('abs(qalt[6]-q6st+2*pi)', {qalt, q6st}), evaluate('abs(qalt[6]-q6st)', {
        qalt,
        q6st
      }), evaluate('abs(qalt[6]-q6st-2*pi)', {qalt, q6st})];
      jtest = tresults.indexOf(Math.min.apply(Math, tresults)) + 1;
      let q6f = evaluate('q6st+(2*jtest-4)*pi', {q6st, jtest});

      gamma = evaluate("acos(y5g'*ltcp)", {y5g, ltcp});
      if (gamma > Math.PI / 2) {
        Vorz = -1;
      } else {
        Vorz = 1;
      }
      q6st = evaluate("Vorz*acos(y5g'*mtcp)", {Vorz, y5g, mtcp});
      tresults = [evaluate('abs(qalt[6]-q6st+2*pi)', {qalt, q6st}), evaluate('abs(qalt[6]-q6st)', {
        qalt,
        q6st
      }), evaluate('abs(qalt[6]-q6st-2*pi)', {qalt, q6st})];
      jtest = tresults.indexOf(Math.min.apply(Math, tresults)) + 1;
      let q6g = evaluate('q6st+(2*jtest-4)*pi', {q6st, jtest});

      gamma = evaluate("acos(y5h'*ltcp)", {y5h, ltcp});
      if (gamma > Math.PI / 2) {
        Vorz = -1;
      } else {
        Vorz = 1;
      }
      q6st = evaluate("Vorz*acos(y5h'*mtcp)", {Vorz, y5h, mtcp});
      tresults = [evaluate('abs(qalt[6]-q6st+2*pi)', {qalt, q6st}), evaluate('abs(qalt[6]-q6st)', {
        qalt,
        q6st
      }), evaluate('abs(qalt[6]-q6st-2*pi)', {qalt, q6st})];
      jtest = tresults.indexOf(Math.min.apply(Math, tresults)) + 1;
      let q6h = evaluate('q6st+(2*jtest-4)*pi', {q6st, jtest});

      qz = evaluate('[q1a, q1a, q1a, q1a, q1b, q1b, q1b, q1b; q2a, q2a, q2b, q2b, q2c, q2c, q2d, q2d; q3a, q3a, q3b, q3b, q3c, q3c, q3d, q3d; q4a, q4b, q4c, q4d, q4e, q4f, q4g, q4h; q5a, q5b, q5c, q5d, q5e, q5f, q5g, q5h; q6a, q6b, q6c, q6d, q6e, q6f, q6g, q6h]', {
        q1a,
        q1b,
        q2a,
        q2b,
        q2c,
        q2d,
        q3a,
        q3b,
        q3c,
        q3d,
        q4a,
        q4b,
        q4c,
        q4d,
        q4e,
        q4f,
        q4g,
        q4h,
        q5a,
        q5b,
        q5c,
        q5d,
        q5e,
        q5f,
        q5g,
        q5h,
        q6a,
        q6b,
        q6c,
        q6d,
        q6e,
        q6f,
        q6g,
        q6h
      });
    } else {
      alert('out of range! will fail... reload page!');
      qz = evaluate('[]');
    }
    return qz;
  }
}

export default R6RTrafo;