import React, {Component} from 'react';
import {Button, Modal, Card, ButtonGroup} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../store.js';
import RobotForm from '../forms/RobotForm';
import download from 'downloadjs';
import FileReaderInput from 'react-file-reader-input';
import R3Planar from '../../data/robots/R3Planar';
import SCARA from '../../data/robots/SCARA';
import R6 from '../../data/robots/R6';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faInfo, faInfoCircle, faPencil, faPlus, faTrash, faUpload} from "@fortawesome/free-solid-svg-icons";
import {allowKinematics} from "../../helpers/configHelper";


class RobotView extends Component {

    store = {
        robotModalOpen: false,
        availableRobots: [
            R3Planar,
            SCARA,
            R6
        ]
    };

    openRobotModal() {
        this.store.robotModalOpen = true;
    }

    closeRobotModal() {
        //if (window.confirm('Änderungen verwerfen?')) {
            this.store.robotModalOpen = false;
        //}
    }

    closeRobotModalAfterSaving() {
        this.store.robotModalOpen = false;
    }

    clearRobot() {
        store.robot.isSet = false;
        store.commands = [];
        store.translation.clearResult();
    }

    downloadRobot() {
        if(store.robot.isSet) {
            let data = JSON.stringify(store.robot.data);
            download(data, store.robot.data.name + '.mdr', 'application/json');
        }
    }

    importRobot(e, results) {
        results.forEach(result => {
            const file = result[1];
            let fr = new FileReader();
            fr.onload = function(e) {
                store.robot.data = JSON.parse(e.target.result);
                store.robot.isSet = true;
            };
            fr.readAsText(file);
        });
    }

    selectRobot(robotData) {
        store.robot.data = robotData;
        store.robot.isSet = true;
        if(store.robot.data.preDefinedMask) {
            store.commands.map((command) => {
                if(command.data && command.data.mask) {
                    command.data.mask = store.robot.data.preDefinedMask;
                }
            });
        }
    }

    render() {
        let detailsImagePath = '';

        if(store.robot.data && store.robot.data.images !== undefined) {
            detailsImagePath = store.robot.data.images.detail;
        }

        return (
            <div className="view-robotView">
                <Card>
                    <Card.Header className="d-flex align-items-center">
                        <Card.Title className="mb-0">
                            Roboter
                        </Card.Title>
                        <div className="ms-auto">
                            {!store.robot.isSet ?
                              <div/>
                              :
                              <ButtonGroup>
                                  <Button onClick={this.openRobotModal} size="sm" variant="outline-secondary">
                                      {allowKinematics() ? (
                                          <FontAwesomeIcon icon={faPencil}/>
                                      ) : (
                                          <FontAwesomeIcon icon={faInfoCircle}/>
                                      )}
                                  </Button>
                                  <Button onClick={this.clearRobot} size="sm" variant="outline-secondary">
                                      <FontAwesomeIcon icon={faTrash}/>
                                  </Button>
                                  {allowKinematics() && (
                                      <Button onClick={this.downloadRobot} size="sm" variant="outline-secondary">
                                          <FontAwesomeIcon icon={faDownload}/>
                                      </Button>
                                  )}
                              </ButtonGroup>
                            }
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {!store.robot.isSet ?
                          <div className="row availableRobots eh">
                              <div className="col-12 mb-4">
                                  Bisher ist kein Roboter definiert.<br/>
                                  Bitte erstellen bzw. importieren Sie einen Roboter oder wählen Sie aus den unten stehenden Beispielen.
                              </div>
                              <div className="col-12 mb-5">
                                  <Button onClick={this.openRobotModal} className="me-3">
                                      <FontAwesomeIcon icon={faPlus} className="me-2"/>
                                      Roboter erstellen
                                  </Button>
                                  <FileReaderInput as="text" id="my-file-input"
                                                   onChange={this.importRobot}>
                                      <Button>
                                          <FontAwesomeIcon icon={faUpload} className="me-2"/>
                                          Roboter hochladen
                                      </Button>
                                  </FileReaderInput>
                              </div>
                              {this.store.availableRobots.map((availableRobot, i) =>
                                  <div className="col-md-4" key={i} onClick={() => this.selectRobot({...availableRobot})}>
                                      <div className="availableRobot">
                                          <h4 className="m-t-0">{availableRobot.name}</h4>
                                          <div className="img-container col-sm-8 col-sm-offset-4">
                                              <img
                                                src={require(`../../data/robots/images/${availableRobot.images.preview}`)}/>
                                          </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            :
                            <div className="row">
                                <div className="col-md-9">
                                    <h4 className="m-t-0">Name: {store.robot.data.name}</h4>
                                    <h5>Gelenke: {store.robot.data.jointAmount}</h5>
                                </div>
                                {detailsImagePath !== '' &&
                                  <div className="col-md-3">
                                      <img style={{width: '100%'}} alt=""
                                           src={require(`../../data/robots/images/${detailsImagePath}`)}/>
                                  </div>
                                }
                            </div>
                        }
                    </Card.Body>
                </Card>
                <Modal show={this.store.robotModalOpen} onHide={this.closeRobotModal} size="xl">
                    <RobotForm closeModal={this.closeRobotModal} readonly={!allowKinematics()}
                               closeModalAfterSaving={this.closeRobotModalAfterSaving}/>
                </Modal>
            </div>
        )
    }
}

export default easyComp(RobotView);
