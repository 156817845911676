import { Object3D, Scene, WebGLRenderer, PerspectiveCamera, AmbientLight, DirectionalLight, GridHelper, PlaneGeometry, MeshBasicMaterial, Mesh, DoubleSide, AxesHelper, Color, Matrix4, Vector3, Euler, Quaternion, Group } from 'three';
import CustomTrack from "./CustomTrack";
import CustomArm from "./CustomArm";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {GUI} from "dat.gui";
import CustomAxis from "./CustomAxis";
import CustomTool from "./CustomTool";

class ThreeDRobot extends Object3D {
    constructor(canvas, table, Q, robotName, settings) {
        super();
        this.table = table;
        this.settings = settings;
        this.robotName = robotName;
        this.scene = new Scene();
        this.gui = null;
        this.points = {};
        this.axis = {};
        this.tool = {};
        this.arms = {};

        this.scene.add(this);

        this.renderer = new WebGLRenderer({
            antialias: true,
            preserveDrawingBuffer: false,
        });
        this.renderer.setSize(canvas.offsetWidth, 700);
        this.renderer.setClearColor(0xFCFCFC);
        canvas.appendChild(this.renderer.domElement);

        this.camera = new PerspectiveCamera(45, canvas.offsetWidth / canvas.offsetHeight, 1, 10000);
        this.camera.up.set(0, 0, 1);
        this.camera.position.set(2, 3.5, 2.5);
        this.scene.add(this.camera);

        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.maxDistance = 500.0;
        controls.maxPolarAngle = Math.PI * 0.495;
        controls.target.set(0, 0, 0);
        controls.addEventListener('change', () => this.renderer.render(this.scene, this.camera));

        const ambientlight = new AmbientLight(0x404040, 5);
        this.scene.add(ambientlight);

        const pointlight = new DirectionalLight(0xffffff, 6);
        pointlight.position.set(1, 1.3, 1).normalize();
        this.scene.add(pointlight);

        const gridHelper = new GridHelper(3, 10);
        gridHelper.rotateX(Math.PI / 2);
        this.scene.add(gridHelper);

        const geometry = new PlaneGeometry(3, 3, 1, 1);
        const material = new MeshBasicMaterial({ color: 0xcccccc });
        const floor = new Mesh(geometry, material);
        floor.material.side = DoubleSide;
        this.scene.add(floor);

        const axisHelper = new AxesHelper(1.5);
        axisHelper.setColors(new Color('red'), new Color('green'), new Color('blue'));
        this.scene.add(axisHelper);

        window.addEventListener('resize', () => {
            this.camera.aspect = canvas.offsetWidth / canvas.offsetHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(canvas.offsetWidth, canvas.offsetHeight);
        });

        this.camera.aspect = canvas.offsetWidth / canvas.offsetHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(canvas.offsetWidth, canvas.offsetHeight);

        this.animate(table, Q);
    }

    animate(table, Q) {
        let ChangeOnRefPath = true;
        let x = 1;
        let lastUpdate = Date.now();

        const loop = () => {
            if (ChangeOnRefPath) {
                this.generateRefPath(table, Q);
                ChangeOnRefPath = false;
            }

            if (this.settings.animate) {
                const now = Date.now();

                if (x < Q.length) {
                    if (now - lastUpdate > this.settings.speed) {
                        for (let i = 0; i < table.length; i++) {
                            if (this.table[i].h === 1) {
                                this.table[i].theta = Q[x][i];
                            } else {
                                this.table[i].d = Q[x][i];
                            }
                        }
                        this.update(x);
                        x++;
                        lastUpdate = now;
                    }
                } else {
                    x = 1;
                }
            }

            this.renderer.render(this.scene, this.camera);
            requestAnimationFrame(loop);
        };

        this.gui = new GUI();
        document.getElementById('gui').appendChild(this.gui.domElement);

        const nullpunkt = this.gui.addFolder('Origin');
        nullpunkt.add(this.settings, 'a', -1.5, 1.5, 0.1).onChange(() => { ChangeOnRefPath = true; });
        nullpunkt.add(this.settings, 'd', -1.5, 1.5, 0.1).onChange(() => { ChangeOnRefPath = true; });
        nullpunkt.add(this.settings, 'theta', 0, 360, 10).onChange(() => { ChangeOnRefPath = true; });
        nullpunkt.add(this.settings, 'alpha', 0, 360, 10).onChange(() => { ChangeOnRefPath = true; });

        const controls = this.gui.addFolder('Controlls');
        controls.add(this.settings, 'speed', 0, 100, 5);
        controls.add(this.settings, 'animate');
        controls.add(this.settings, 'waypoints');
        controls.add(this.settings, 'refpath').onChange(() => { ChangeOnRefPath = true; });

        const endeffektor = this.gui.addFolder('Endeffektorposition');
        endeffektor.open();
        const end = { pos: '', euler: '' };
        endeffektor.add(end, 'pos').listen();
        endeffektor.add(end, 'euler').listen();

        this.end = end;
        loop();
    }

    update(x) {
        const table = [
            {
                a: this.settings.a,
                d: this.settings.d,
                theta: this.settings.theta * (Math.PI / 180),
                alpha: this.settings.alpha * (Math.PI / 180)
            },
            ...this.table
        ];

        const FK = this.constructor.computeFK(table);
        FK.push(new Matrix4());
        const tooltype = "gripper";

        for (let i = 0; i < FK.length - 1; i++) {
            const a1 = new Vector3().setFromMatrixPosition(FK[i]);
            const a2 = new Vector3().setFromMatrixPosition(FK[i + 1]);

            const last = i === FK.length - 2;
            if (last) {
                this.end.pos = `${a1.x.toFixed(2)}, ${a1.y.toFixed(2)}, ${a1.z.toFixed(2)}`;
                const euler = new Euler().setFromRotationMatrix(FK[i], 'XYZ');
                this.end.euler = `${(euler.x * 180 / Math.PI).toFixed(2)} ${(euler.y * 180 / Math.PI).toFixed(2)} ${(euler.z * 180 / Math.PI).toFixed(2)}`;

                if (this.settings.waypoints) {
                    const waypoint = new Float32Array([a1.x, a1.y, a1.z]);
                    if (!this.points[x]) {
                        this.points[x] = new CustomTrack(waypoint, 0x000000);
                        this.add(this.points[x]);
                    } else {
                        this.points[x].geometry.attributes.position.setXYZ(0, ...waypoint);
                        this.points[x].geometry.attributes.position.needsUpdate = true;
                    }
                } else {
                    for (const key in this.points) {
                        this.remove(this.points[key]);
                        this.points[key].geometry?.dispose();
                        this.points[key].material?.dispose();
                        delete this.points[key];
                    }
                }
            }

            if (!this.axis[i]) {
                this.axis[i] = new CustomAxis(last);
                this.add(this.axis[i]);
            }
            this.axis[i].position.setFromMatrixPosition(FK[i]);
            this.axis[i].rotation.setFromRotationMatrix(FK[i]);

            if ((!this.tool[i]) && last) {
                this.tool[i] = new CustomTool(tooltype, this.robotName);
                this.add(this.tool[i]);
            }
            this.tool[i]?.position.setFromMatrixPosition(FK[i]);
            this.tool[i]?.rotation.setFromRotationMatrix(FK[i]);

            if (i < FK.length - 2) {
                const jointParams = table[i + 1];
                if (!jointParams) continue;

                const startPoint = new Vector3(a1.x, a1.y, a1.z);
                const endPoint = new Vector3(a2.x, a2.y, a2.z);
                const length = startPoint.distanceTo(endPoint);

                if (!this.arms[i]) {
                    this.arms[i] = new CustomArm(length, i, jointParams);
                    this.arms[i].meta = { lastlength: length };
                    this.add(this.arms[i]);
                }

                const arm = this.arms[i];
                arm.position.addVectors(startPoint, endPoint).multiplyScalar(0.5);

                if (arm.meta.lastlength !== length.toFixed(2)) {
                    const scaleRatio = length.toFixed(2) / arm.meta.lastlength;
                    const dir = arm.ExtrusionDirection.direction;
                    if (dir === "X") arm.scale.x *= scaleRatio;
                    else if (dir === "Y") arm.scale.y *= scaleRatio;
                    else if (dir === "Z" || dir === "schraeggelenk") arm.scale.z *= scaleRatio;
                    arm.meta.lastlength = length.toFixed(2);
                }

                if (arm.ExtrusionDirection.direction !== "schraeggelenk") {
                    const quaternion = new Quaternion();
                    FK[i + 1].decompose(new Vector3(), quaternion, new Vector3());
                    arm.setRotationFromQuaternion(quaternion);
                } else {
                    arm.lookAt(startPoint);
                }
            }
        }
    }

    generateRefPath(table, Q) {
        if (this.settings.refpath) {
            if (this.refpath) this.remove(this.refpath);
            this.refpath = new Group();
            const points = ThreeDRobot.CalcTracepoints(table, Q, this.settings);
            for (const point of points) {
                const tracepoint = new CustomTrack(point, 0x00ff00);
                tracepoint.geometry.attributes.position.needsUpdate = true;
                this.refpath.add(tracepoint);
            }
            this.add(this.refpath);
        } else {
            if (this.refpath) this.remove(this.refpath);
        }
    }

    static computeFK(dh) {
        const results = [];
        let result = new Matrix4();

        dh.forEach((params) => {
            const a = params.a;
            const d = params.d;
            const ct = Math.cos(params.theta);
            const st = Math.sin(params.theta);
            const ca = Math.cos(params.alpha);
            const sa = Math.sin(params.alpha);

            const matrix = new Matrix4();
            matrix.set(
                ct, -st * ca, st * sa, a * ct,
                st, ct * ca, -ct * sa, a * st,
                0, sa, ca, d,
                0, 0, 0, 1
            );
            result = result.multiply(matrix);
            results.push(result.clone());
        });

        return results;
    }

    static CalcTracepoints(table, Q, settings) {
        const results = [];
        const tempTable = Object.create(table);

        const startpoint = {
            a: settings.a,
            d: settings.d,
            theta: settings.theta * (Math.PI / 180),
            alpha: settings.alpha * (Math.PI / 180)
        };
        tempTable.unshift(startpoint);

        for (let x = 0; x < Q.length; x++) {
            for (let i = 0; i < tempTable.length - 1; i++) {
                if (tempTable[i + 1].h === 1) {
                    tempTable[i + 1].theta = Q[x][i];
                } else {
                    tempTable[i + 1].d = Q[x][i];
                }
            }
            const FK = ThreeDRobot.computeFK(tempTable);
            const i = FK.length - 1;
            const a2 = new Vector3();
            a2.setFromMatrixPosition(FK[i]);

            const disc = (Math.random() * (0.008 - (-0.01))) + (-0.01);
            const waypoint = new Float32Array([a2.x + disc, a2.y + disc, a2.z + disc]);
            results.push(waypoint);
        }

        return results;
    }
}

export default ThreeDRobot;