import React, {Component} from 'react';
import {FormGroup, Form, InputGroup, FormControl, Button, Row, Col, Modal, Card} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../store.js';
import PathReferenceModal from '../modals/PathReferenceModal';
import TranslationService from '../../services/TranslationService';
//import ThreeDAnimationModal from "../modals/ThreeDAnimationModal";
import RobotAnimationModal from "../modals/RobotAnimationModal";

class TranslationView extends Component {

    store = {
        pathReferenceModalOpen: false,
        RobotAnimationModalOpen: false
    };

    runTranslation() {
        window.localStorage.store = {...store};
        window.localStorage.translation = {};
        let ts = new TranslationService();
        ts.run();
    }

    tIpoChange(e) {
        if (/^-?\d*$/.test(e.target.value)) {
            store.translation.tIpoMs = e.target.value;
        }
    }

    showPathReference() {
        this.store.pathReferenceModalOpen = true;
    }

    closePathReferenceModal() {
        this.store.pathReferenceModalOpen = false;
    }

    showThreeDAnimation() {
        this.store.RobotAnimationModalOpen = true;
        this.setState({ RobotAnimationModalOpen: true });
    }

    closeRobotAnimationModal() {
        this.setState({ RobotAnimationModalOpen: false });
        this.store.RobotAnimationModalOpen= false;
    }

    render() {
        return store.robot.isSet ? (
            <div className="mt-4">
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">
                            Übersetzung
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col xs={4}>
                                <FormGroup>
                                    <Form.Label>
                                        T_Ipo
                                    </Form.Label>
                                    <InputGroup>
                                        <FormControl
                                            type="text"
                                            value={store.translation.tIpoMs}
                                            onChange={this.tIpoChange}
                                            name="tIpoMs"
                                        />
                                        <InputGroup.Text>ms</InputGroup.Text>
                                        <Button onClick={this.runTranslation}>
                                            Run
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col xs={{
                                span: 6,
                                offset: 2
                            }}>
                                {store.translation.inAction ? (
                                    <div>
                                        <b>Aktuelle Übersetzung:</b> {store.translation.currentAction}<br/>
                                        <b>Übersetzungszeit:</b> {store.translation.timePassed / 1000} s<br/>
                                        <b>Status:</b> {store.translation.status}
                                        {store.translation.errorMessage ? (
                                            <div className="text-danger">
                                                <b>Fehler:</b> {store.translation.errorMessage}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                                {store.translation.hasResult ? (
                                    <div className="btn-group">
                                        <Button onClick={this.showPathReference} variant="outline-primary">
                                            Show path reference
                                        </Button>
                                        <Modal show={this.store.pathReferenceModalOpen} onHide={this.closePathReferenceModal} size="xl">
                                            <PathReferenceModal closeModal={this.closePathReferenceModal}/>
                                        </Modal>

                                        <Button onClick={this.showThreeDAnimation} variant="outline-success">
                                            Show 3D animation
                                        </Button>

                                        <Modal show={this.store.RobotAnimationModalOpen} onHide={this.closeRobotAnimationModal} size="xl" backdrop="static" keyboard={false}>
                                            {this.store.RobotAnimationModalOpen ? (
                                              <RobotAnimationModal closeModal={this.closeRobotAnimationModal} RobotAnimationModalOpen={this.store.RobotAnimationModalOpen}/>
                                            ) : null}
                                        </Modal>
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        ) : null
    }
}


export default easyComp(TranslationView);