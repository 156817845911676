import React, {Component} from 'react';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import {
    Modal,
    FormControl,
    Button,
    Alert,
    Row,
    Col,
    FormGroup,
    Form,
    InputGroup,
    DropdownButton,
    Table, Dropdown
} from 'react-bootstrap';
import store from '../../store.js'

import RobotFormJoin from '../elements/RobotFormJoint';
import {allowKinematics} from "../../helpers/configHelper";

class RobotForm extends Component {

    store = {
        robotData: {
            name: '',
            vmaxpo: '1.2',
            vmaxori: '200',
            amaxpo: '4',
            amaxori: '500',
            jointAmount: 4,
            joints: [],
            preDefinedMask: [false, false, false, false, false, false],
        },
        robotDataErrors: []
    };

    textFieldChange(e) {
        this.store.robotData[e.target.name] = e.target.value;
    }

    numberFieldChange(e) {
        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            this.store.robotData[e.target.name] = e.target.value;
        }
    }

    jointAmountChange(val) {
        val = parseInt(val);
        this.store.robotData.jointAmount = val;
        if (val < this.store.robotData.joints.length) {
            this.store.robotData.joints.splice(val);
        }
        if (val > this.store.robotData.joints.length) {
            let amount = this.store.robotData.jointAmount - this.store.robotData.joints.length
            for (let i = 0; i < amount; i++) {
                this.store.robotData.joints.push({
                    theta: '',
                    d: '',
                    a: '',
                    alpha: '',
                    qmin: '',
                    qmax: '',
                    qdmax: '',
                    qddmax: '',
                    h: 0
                });
            }
        }
    }

    jointNumberFieldChange(e) {
        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            this.store.robotData.joints[e.target.getAttribute('jointkey')][e.target.name] = e.target.value;
        }
    }

    jointHChange(val, e) {
        this.store.robotData.joints[e.target.getAttribute('jointkey')].h = parseInt(val);
    }

    maskChange(val, e) {
        this.store.robotData.preDefinedMask[e.target.getAttribute('jointkey')] = val;
    }

    saveRobot() {
        this.store.robotDataErrors = [];
        if (this.validateRobotData()) {
            store.robot.data = {...this.store.robotData};
            store.robot.isSet = true;
            this.props.closeModalAfterSaving();
        }
    }

    validateRobotData() {
        let hasErrors = false;

        if (this.store.robotData.name.length < 1) {
            this.store.robotDataErrors.push('Bitte geben Sie eine Bezeichnung für den Roboter an.');
            hasErrors = true;
        }

        return !hasErrors;
    }

    componentWillMount() {
        if (store.robot.isSet) {
            this.store.robotData = {...store.robot.data};
        } else {
            for (let i = 0; i < this.store.robotData.jointAmount; i++) {
                this.store.robotData.joints.push({
                    theta: '',
                    d: '',
                    a: '',
                    alpha: '',
                    qmin: '',
                    qmax: '',
                    qdmax: '',
                    qddmax: '',
                    h: 0
                })
            }
        }
    }

    render() {

        const {robotData} = this.store;
        const {jointNumberFieldChange, jointHChange} = this;
        const {readonly} = this.props;

        return (
            <div className="form-robotForm">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormControl
                            type="text"
                            value={robotData.name}
                            placeholder="Bezeichnung"
                            onChange={this.textFieldChange}
                            name="name"
                            className="input-name"
                            readOnly={readonly}
                            size="lg"
                        />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.store.robotDataErrors.length > 0 ?
                        <Alert bsStyle="warning">
                            {this.store.robotDataErrors.map(function (message, i) {
                                return <div key={i}>{message}</div>
                            })}
                        </Alert>
                        : null}

                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <Form.Label>
                                    vmax, pos
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        value={robotData.vmaxpo}
                                        onChange={this.numberFieldChange}
                                        name="vmaxpo"
                                        readOnly={readonly}
                                    />
                                    <InputGroup.Text>m/s</InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Form.Label>
                                    vmax, ori
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        value={robotData.vmaxori}
                                        onChange={this.numberFieldChange}
                                        name="vmaxori"
                                        readOnly={readonly}
                                    />
                                    <InputGroup.Text>deg/s</InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Form.Label>
                                    amax, pos
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        value={robotData.amaxpo}
                                        onChange={this.numberFieldChange}
                                        name="amaxpo"
                                        readOnly={readonly}
                                    />
                                    <InputGroup.Text>m/s<sup>2</sup></InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xs={3}>
                            <FormGroup>
                                <Form.Label>
                                    amax, ori
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        value={robotData.amaxori}
                                        onChange={this.numberFieldChange}
                                        name="amaxori"
                                        readOnly={readonly}
                                    />
                                    <InputGroup.Text>deg/s<sup>2</sup></InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col xs={6}>
                            {!readonly ? (
                                <DropdownButton
                                    title={robotData.jointAmount + ' Gelenk' + (robotData.jointAmount > 1 ? 'e' : '')}
                                    onSelect={this.jointAmountChange}
                                    id="jointAmount"
                                    variant="outline-primary"
                                >
                                    {[...Array(10).keys()].map(function (x) {
                                        let i = x + 1;
                                        return <Dropdown.Item key={i} active={i === robotData.jointAmount} eventKey={i}>{i} Gelenk{i > 1 ? 'e' : null}</Dropdown.Item>
                                    })}
                                </DropdownButton>
                            ) : (
                                <h3>
                                    Gelenke
                                </h3>
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12}>
                            <Table>
                                <thead>
                                <tr>
                                    <th/>
                                    <th>
                                        theta
                                    </th>
                                    <th>
                                        d
                                    </th>
                                    <th>
                                        a
                                    </th>
                                    <th>
                                        alpha
                                    </th>
                                    <th>
                                        qmin
                                    </th>
                                    <th>
                                        qmax
                                    </th>
                                    <th>
                                        qdmax
                                    </th>
                                    <th>
                                        qddmax
                                    </th>
                                    <th>
                                        joint
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {[...Array(robotData.jointAmount).keys()].map(function (x) {
                                    return <RobotFormJoin key={x} i={x} robotData={robotData}
                                                          jointNumberFieldChange={jointNumberFieldChange}
                                                          jointHChange={jointHChange} readonly={readonly}/>
                                })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <h6 className="mb-2">
                                mask-Vektor (Auswahl der unabhängigen kartesischen Koordinaten)
                            </h6>

                            <div>
                                <table className="table table-sm table-bordered">
                                    <tbody>
                                    <tr>
                                        {robotData.preDefinedMask?.map((mask, i) => (
                                          <td key={i}>
                                              <Form.Check type="checkbox" checked={mask} key={i}
                                                          onChange={(e) => this.maskChange(e.target.checked, e)}
                                                          jointkey={i} disabled={readonly}/>
                                          </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        {robotData.preDefinedMask?.map((_, i) => (
                                          <td key={i}>
                                              {[<i>p<sub>x</sub></i>, <i>p<sub>y</sub></i>, <i>p<sub>z</sub></i>, <i>A</i>, <i>B</i>, <i>C</i>][i]}
                                          </td>
                                        ))}
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {allowKinematics() ? (
                        <>
                            <Button onClick={this.props.closeModal} variant="outline-secondary">Abbrechen</Button>
                            <Button onClick={this.saveRobot} variant="success">Speichern</Button>
                        </>
                    ) : (
                        <Button onClick={this.props.closeModal} variant="outline-secondary">Schließen</Button>
                    )}
                </Modal.Footer>
            </div>
        )
    }
}

export default easyComp(RobotForm);