import React, {Component} from 'react';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../store';
import CommandFormTargetPose from './commands/CommandFormTargetPose';
import CommandFormSpeedPtp from './commands/CommandFormSpeedPtp';
import CommandFormAuxiliaryPointAndTargetPose from './commands/CommandFormAuxiliaryPointAndTargetPose';
import CommandComment from './commands/CommandComment';
import CommandWait from './commands/CommandWait';
import CommandFormTargetPoseModel from '../../model/CommandFormTargetPoseModel';
import CommandFormSpeedPtpModel from '../../model/CommandFormSpeedPtpModel';
import CommandCommentModel from "../../model/CommandCommentModel";
import CommandFormAuxiliaryPointAndTargetPoseModel from "../../model/CommandFormAuxiliaryPointAndTargetPoseModel";
import CommandWaitModel from "../../model/CommandWaitModel";
import CommandFormSpeedCpModel from "../../model/CommandFormSpeedCpModel";
import CommandFormSpeedCp from "./commands/CommandFormSpeedCp";
import CommandFormAccelCp from "./commands/CommandFormAccelCp";
import CommandFormAccelCpModel from "../../model/CommandFormAccelCpModel";
import CommandGripperModel from "../../model/CommandGripperModel";
import CommandGripper from "./commands/CommandGripper";

class CommandForm extends Component {

    store = {
        command: null
    };

    commandTypeChange(val) {
        store.commands[this.store.command.index].type = val;
        switch (val) {
            case 'PTP':
            case 'PTPREL':
                store.commands[this.store.command.index].data = new CommandFormTargetPoseModel(store.robot, 2);
                break;
            case 'LIN':
            case 'LINREL':
                store.commands[this.store.command.index].data = new CommandFormTargetPoseModel(store.robot, 1);
                break;
            case 'SPEEDPTP':
            case 'ACCELPTP':
                store.commands[this.store.command.index].data = new CommandFormSpeedPtpModel();
                break;
            case 'CIRC':
            case 'CIRCREL':
                store.commands[this.store.command.index].data =  new CommandFormAuxiliaryPointAndTargetPoseModel(store.robot);
                break;
            case 'SPEEDCP':
                store.commands[this.store.command.index].data = new CommandFormSpeedCpModel();
                break;
            case 'ACCELCP':
                store.commands[this.store.command.index].data = new CommandFormAccelCpModel();
                break;
            case 'OPENGRIPPER':
            case 'CLOSEGRIPPER':
                store.commands[this.store.command.index].data = new CommandGripperModel();
                break;
            case 'COMMENT':
                store.commands[this.store.command.index].data = new CommandCommentModel();
                break;
            case 'WAIT':
                store.commands[this.store.command.index].data = new CommandWaitModel();
                break;
            default:
                store.commands[this.store.command.index].data = null;
                break;
        }
    }

    render() {
        let command = store.commands.find(function (obj) {
            return obj.id === parseInt(store.selectedCommand, 10);
        });
        //let { command } = this.store;
        if (command !== undefined) {
            this.store.command = command;
            return (
                <div>
                    <DropdownButton
                        title={command.type ? command.type : <i>bitte auswählen</i>}
                        id='selectCommandType'
                        onSelect={this.commandTypeChange}
                        variant="secondary"
                    >
                        <Dropdown.Item eventKey="PTP">PTP</Dropdown.Item>
                        <Dropdown.Item eventKey="PTPREL">PTPREL</Dropdown.Item>
                        <Dropdown.Item eventKey="SPEEDPTP">SPEEDPTP</Dropdown.Item>
                        <Dropdown.Item eventKey="ACCELPTP">ACCELPTP</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="LIN">LIN</Dropdown.Item>
                        <Dropdown.Item eventKey="LINREL">LINREL</Dropdown.Item>
                        <Dropdown.Item eventKey="CIRC">CIRC</Dropdown.Item>
                        <Dropdown.Item eventKey="CIRCREL">CIRCREL</Dropdown.Item>
                        <Dropdown.Item eventKey="SPEEDCP">SPEEDCP</Dropdown.Item>
                        <Dropdown.Item eventKey="ACCELCP">ACCELCP</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="OPENGRIPPER">OPEN GRIPPER</Dropdown.Item>
                        <Dropdown.Item eventKey="CLOSEGRIPPER">CLOSE GRIPPER</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="HOME">HOME</Dropdown.Item>
                        <Dropdown.Item eventKey="WAIT">WAIT</Dropdown.Item>
                        <Dropdown.Item eventKey="COMMENT">COMMENT</Dropdown.Item>
                    </DropdownButton>
                    <hr/>
                    {{
                        'PTP': (
                            <CommandFormTargetPose command={command}/>
                        ),
                        'PTPREL': (
                            <CommandFormTargetPose command={command}/>
                        ),
                        'SPEEDPTP': (
                            <CommandFormSpeedPtp command={command}/>
                        ),
                        'ACCELPTP': (
                            <CommandFormSpeedPtp command={command}/>
                        ),
                        'LIN': (
                            <CommandFormTargetPose command={command}/>
                        ),
                        'LINREL': (
                            <CommandFormTargetPose command={command}/>
                        ),
                        'CIRC': (
                            <CommandFormAuxiliaryPointAndTargetPose command={command}/>
                        ),
                        'CIRCREL': (
                            <CommandFormAuxiliaryPointAndTargetPose command={command}/>
                        ),
                        'SPEEDCP': (
                            <CommandFormSpeedCp command={command}/>
                        ),
                        'ACCELCP': (
                            <CommandFormAccelCp command={command}/>
                        ),
                        'OPENGRIPPER': (
                            <CommandGripper command={command}/>
                        ),
                        'CLOSEGRIPPER': (
                            <CommandGripper command={command}/>
                        ),
                        'COMMENT': (
                            <CommandComment command={command}/>
                        ),
                        'WAIT': (
                            <CommandWait command={command}/>
                        )

                    }[command.type]}
                </div>
            )
        } else {
            return (
                <div/>
            )
        }
    }
}

export default easyComp(CommandForm);
