import {easyStore} from 'react-easy-state/dist/umd.es6';

export default easyStore({
    robot: {
        isSet: false,
        data: {
            name: '',
            vmaxpo: '',
            vmaxori: '',
            amaxpo: '',
            amaxori: '',
            jointAmount: 0
        },
    },

    commandIdSequence: 0,
    commands: [],
    selectedCommand: null,
    refreshCommandsIndex: function () {
        for (let i = 0; i < this.commands.length; i++) {
            this.commands[i].index = i;
            if (this.commands[i].id === null) {
                this.commands[i].id = this.commandIdSequence;
                this.selectedCommand = this.commandIdSequence;
                this.commandIdSequence++;
            }
        }
    },

    commandValueChanged: function () {
        this.translation.clearResult();
    },

    translation: {
        tIpoMs: 10,
        inAction: false,
        hasResult: false,
        status: 'Ok',
        errorMessage: null,
        currentAction: '',
        startedAt: 0,
        timePassed: 0,
        timePassedInterval: null,

        input: {},
        output: {
            Q: []
        },

        stopWithError: function (message) {
            this.translation.status = 'Error';
            this.translation.errorMessage = message;
            if (this.translation.timePassedInterval !== null) {
                clearInterval(this.translation.timePassedInterval);
            }
        },
        clearResult: function () {
            this.inAction = false;
            this.hasResult = false;
            this.status = 'Ok';
            this.errorMessage = null;
            this.currentAction = '';
            this.startedAt = 0;
            this.timePassed = 0;
            this.input = {};
            this.output = {
                Q: []
            };
            if (this.timePassedInterval !== null) {
                clearInterval(this.timePassedInterval);
            }
        }
    }
});
