import React, {Component} from 'react';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import {
    Modal,
    Button,
    Row,
    Col,
    DropdownButton,
    Dropdown
} from 'react-bootstrap';
import store from '../../store.js'
import Plotly from 'plotly.js/dist/plotly';

import Fkin from '../../services/translation/Fkin';
import Rotmat2eul from "../../services/translation/Rotmat2eul";
import {squeezeInnerOneLevel} from "../../helpers/matrixHelper";

let math = require('mathjs');

class PathReferenceModal extends Component {

    store = {
        jointValuesSelected: -1,
        jointVelocitySelected: false,
        cartesian1Selected: false,
        cartesian1Strings: {
            0: "x-y-plane",
            1: "y-z-plane",
            2: "x-z-plane",
            3: "Euler-angle A(t)",
            4: "Euler-angle B(t)",
            5: "Euler-angle C(t)"
        }
    };

    componentDidMount() {
        this.renderJointValues();
    }

    jointIsRotating(index) {
        try {
            return store.translation.input.robot.joints[index].h === 1
        } catch(e) {
            return false
        }
    }

    renderJointValues(jointIndex) {
        let Q = store.translation.output.Q;
        let plotData = [];
        let trace;
        let layout;

        if(jointIndex !== undefined) {
            trace = {x: [], y: [], name: 'Joint ' + (jointIndex + 1)};
            for(let j = 0; j < Q.length; j++) {
                trace.x.push(j * store.translation.input.tIpo);
                trace.y.push(math.round(Q[j][jointIndex] * (this.jointIsRotating(jointIndex) ? 180/Math.PI : 1), 4));
            }
            plotData.push(trace);
            layout = {
                xaxis: {
                    title: 'sec'
                },
                yaxis: {
                    title: `joint ${jointIndex + 1} variable in ` + (this.jointIsRotating(jointIndex) ? 'deg' : 'm')
                }
            };
            this.store.jointValuesSelected = jointIndex;
        } else {
            for(let i = 0; i < Q[0].length; i++) {
                trace = {x: [], y: [], name: 'Joint ' + (i + 1)};
                if(!this.jointIsRotating(i)) trace.yaxis = 'y2';
                for(let j = 0; j < Q.length; j++) {
                    trace.x.push(j * store.translation.input.tIpo);
                    trace.y.push(math.round(Q[j][i] * (this.jointIsRotating(i) ? 180/Math.PI : 1), 4));
                }
                plotData.push(trace);
            }
            layout = {
                xaxis: {
                    title: 'sec',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: 'joint variable in deg',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis2: {
                    title: 'joint variable in m',
                    overlaying: 'y',
                    side: 'right',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                legend: {"orientation": "h", y: 1.1, x: 0}
            };
            this.store.jointValuesSelected = -1;
        }
        Plotly.newPlot('pathPlot', plotData, layout, {
            modeBarButtonsToAdd: [{
                name: 'Save as SVG',
                icon: Plotly.Icons.camera,
                click: function(gd) {
                    Plotly.downloadImage(gd, {format: 'svg'})
                }
            }]
        });
        this.store.jointVelocitySelected = false;
    }

    renderJointVelocities(jointIndex) {
        let Q = store.translation.output.Q;
        let plotData = [];
        let trace;
        let layout;

        if(jointIndex !== undefined) {
            trace = {x: [], y: [], name: 'Joint ' + (jointIndex + 1)};
            let jo = 0;
            for(let j = 0; j < Q.length; j++) {
                trace.x.push(j * store.translation.input.tIpo);
                if(j === 0) {
                    trace.y.push(0);
                } else {
                    trace.y.push(math.round((Q[j][jointIndex] - Q[j-1][jointIndex]) / store.translation.input.tIpo * (this.jointIsRotating(jointIndex) ? 180/Math.PI : 1), 4));
                }
                jo = j;
            }
            trace.x.push((jo+1) * store.translation.input.tIpo);
            trace.y.push(0);
            plotData.push(trace);
            layout = {
                xaxis: {
                    title: 'sec',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: `joint ${jointIndex + 1} velocity in ` + (this.jointIsRotating(jointIndex) ? 'deg' : 'm') + '/s',
                    gridcolor: "rgba(0,0,0,0.5)",
                }
            };
            this.store.jointVelocitySelected = jointIndex;
        } else {
            for(let i = 0; i < Q[0].length; i++) {
                trace = {x: [], y: [], name: 'Joint ' + (i + 1)};
                if(!this.jointIsRotating(i)) trace.yaxis = 'y2';
                let jo = 0;
                for(let j = 0; j < Q.length; j++) {
                    trace.x.push(j * store.translation.input.tIpo);
                    if(j === 0) {
                        trace.y.push(0);
                    } else {
                        trace.y.push(math.round((Q[j][i] - Q[j-1][i]) / store.translation.input.tIpo * (this.jointIsRotating(i) ? 180/Math.PI : 1), 4));
                    }
                    jo = j;
                }
                trace.x.push((jo+1) * store.translation.input.tIpo);
                trace.y.push(0);
                plotData.push(trace);
            }
            layout = {
                xaxis: {
                    title: 'sec',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: 'joint velocity in deg/s',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis2: {
                    title: 'joint velocity in m/s',
                    overlaying: 'y',
                    side: 'right',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                legend: {"orientation": "h", y: 1.1, x: 0}
            };
            this.store.jointVelocitySelected = -1;
        }
        Plotly.newPlot('pathPlot', plotData, layout, {
            modeBarButtonsToAdd: [{
                name: 'Save as SVG',
                icon: Plotly.Icons.camera,
                click: function(gd) {
                    Plotly.downloadImage(gd, {format: 'svg'})
                }
            }]
        });
        this.store.jointValuesSelected = false;
    }

    renderCartesian1(dataToShow) {
        this.store.jointValuesSelected = false;
        this.store.jointVelocitySelected = false;
        this.store.cartesian1Selected = parseInt(dataToShow);

        let Q = store.translation.output.Q;
        let robot = store.translation.input.robot;
        let N = robot.joints.length;
        let plotData = [];
        let trace;
        let T_T0;
        let fkin = new Fkin();
        let rotmat2eul = new Rotmat2eul();
        let xyz = math.zeros([3, N]);
        let eul = math.zeros([3, N]);
        let layout;

        for(let i = 0; i < Q.length; i++) {
            T_T0 = fkin.run(robot, Q[i]);

            xyz = math.subset(xyz, math.index([0, 1, 2], i), squeezeInnerOneLevel(math.subset(T_T0[1], math.index([0, 1, 2], 3, (N-1)))));
            eul = math.subset(eul, math.index([0, 1, 2], i), rotmat2eul.run(math.squeeze(math.subset(T_T0[1], math.index([0, 1, 2], [0, 1, 2], (N-1))))));
        }

        trace = {x: [], y: [], name: this.store.cartesian1Strings[dataToShow]};

        if(dataToShow === "0") {
            for(let i = 0; i < Q.length; i++) {
                trace.x.push(math.round(math.subset(xyz, math.index(0, i)), 4));
                trace.y.push(math.round(math.subset(xyz, math.index(1, i)), 4));
            }
            layout = {
                xaxis: {
                    title: 'x<sub>0</sub>',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: 'y<sub>0</sub>',
                    gridcolor: "rgba(0,0,0,0.5)",
                }
            };
        }
        else if(dataToShow === "1") {
            for(let i = 0; i < Q.length; i++) {
                trace.x.push(math.round(math.subset(xyz, math.index(1, i)), 4));
                trace.y.push(math.round(math.subset(xyz, math.index(2, i)), 4));
            }
            layout = {
                xaxis: {
                    title: 'y<sub>0</sub>',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: 'z<sub>0</sub>',
                    gridcolor: "rgba(0,0,0,0.5)",
                }
            };
        }
        else if(dataToShow === "2") {
            for(let i = 0; i < Q.length; i++) {
                trace.x.push(math.round(math.subset(xyz, math.index(0, i)), 4));
                trace.y.push(math.round(math.subset(xyz, math.index(2, i)), 4));
            }
            layout = {
                xaxis: {
                    title: 'x<sub>0</sub>',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: 'z<sub>0</sub>',
                    gridcolor: "rgba(0,0,0,0.5)",
                }
            };
        }
        else if(dataToShow === "3") {
            for(let i = 0; i < Q.length; i++) {
                trace.x.push(i * store.translation.input.tIpo);
                trace.y.push(math.round(math.subset(eul, math.index(0, i)) * 180/Math.PI, 4));
            }
            layout = {
                xaxis: {
                    title: 'sec',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: 'A in deg',
                    gridcolor: "rgba(0,0,0,0.5)",
                }
            };
        }
        else if(dataToShow === "4") {
            for(let i = 0; i < Q.length; i++) {
                trace.x.push(i * store.translation.input.tIpo);
                trace.y.push(math.round(math.subset(eul, math.index(1, i)) * 180/Math.PI, 4));
            }
            layout = {
                xaxis: {
                    title: 'sec',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: 'B in deg',
                    gridcolor: "rgba(0,0,0,0.5)",
                }
            };
        }
        else if(dataToShow === "5") {
            for(let i = 0; i < Q.length; i++) {
                trace.x.push(i * store.translation.input.tIpo);
                trace.y.push(math.round(math.subset(eul, math.index(2, i)) * 180/Math.PI, 4));
            }
            layout = {
                xaxis: {
                    title: 'sec',
                    gridcolor: "rgba(0,0,0,0.5)",
                },
                yaxis: {
                    title: 'C in deg',
                    gridcolor: "rgba(0,0,0,0.5)",
                }
            };
        }

        plotData.push(trace);
        Plotly.newPlot('pathPlot', plotData, layout, {
            modeBarButtonsToAdd: [{
                name: 'Save as SVG',
                icon: Plotly.Icons.camera,
                click: function(gd) {
                    Plotly.downloadImage(gd, {format: 'svg'})
                }
            }]
        });
    }

    render() {
        const { jointValuesSelected, jointVelocitySelected, cartesian1Selected, cartesian1Strings } = this.store;
        return (
            <div>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Path Reference
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={3}>
                            <div>
                                <label>
                                    joint values
                                </label>
                            </div>
                            <DropdownButton
                                title={jointValuesSelected !== false ? (jointValuesSelected > -1 ? 'Joint ' + (parseInt(`${jointValuesSelected}`) + 1) : 'all joints') : ''}
                                id='selectCommandType'
                                onSelect={(val) => {val !== '' ? this.renderJointValues(val) : this.renderJointValues();}}
                            >
                                {store.translation.output.Q[0].map((x, i) => (
                                    <Dropdown.Item eventKey={i} key={i}>Joint {(i+1)}</Dropdown.Item>
                                ))}
                                <Dropdown.Item eventKey="">all Joints</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col md={3}>
                            <div>
                                <label>
                                    joint velocities
                                </label>
                            </div>
                            <DropdownButton
                                title={jointVelocitySelected !== false ? (jointVelocitySelected > -1 ? 'Joint ' + (parseInt(`${jointVelocitySelected}`) + 1) : 'all joints') : ''}
                                id='selectCommandType' className="block"
                                onSelect={(val) => {val !== '' ? this.renderJointVelocities(val) : this.renderJointVelocities();}}
                            >
                                {store.translation.output.Q[0].map((x, i) => (
                                    <Dropdown.Item eventKey={i} key={i}>Joint {(i+1)}</Dropdown.Item>
                                ))}
                                <Dropdown.Item eventKey="">all Joints</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col md={3}>
                            <div>
                                <label>
                                    cartesian
                                </label>
                            </div>
                            <DropdownButton
                                title={cartesian1Selected !== false ? cartesian1Strings[cartesian1Selected] : ''}
                                className="block"
                                id="cartesian1Select"
                                onSelect={(val) => {val !== '' ? this.renderCartesian1(val) : this.renderCartesian1(0);}}
                            >
                                {Object.keys(cartesian1Strings).map(e => (
                                    <Dropdown.Item eventKey={e} key={e} value={e}>{cartesian1Strings[e]}</Dropdown.Item>
                                ))}

                            </DropdownButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div id="pathPlot"/>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.closeModal}>Schließen</Button>
                </Modal.Footer>
            </div>
        )
    }
}

export default easyComp(PathReferenceModal);
