import React, {Component} from 'react';
import {Row, Col, FormGroup, ControlLabel, DropdownButton, MenuItem, InputGroup, FormControl} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../../store.js';

class CommandFormSpeedCp extends Component {

    valueChange(index, value) {
        if (/^-?\d*\.?\d*$/.test(value)) {
            let intVal = parseInt(value, 10)
            if((intVal >= 0 && intVal <= 100) || (isNaN(intVal) && value.length === 0)) {
                this.props.command.data.values[index] = value;
                store.commandValueChanged();
            }
        }
    }

    render() {
        let command = this.props.command;
        const { valueChange } = this;

        return (
            <Row>
                <Col xs={6}>
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Text>v_max,pos</InputGroup.Text>
                            <FormControl type="text" onChange={(e) => valueChange(0, e.target.value)}
                                         value={command.data.values[0]}/>
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Text>v_max,ori</InputGroup.Text>
                            <FormControl type="text" onChange={(e) => valueChange(1, e.target.value)}
                                         value={command.data.values[1]}/>
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}
export default easyComp(CommandFormSpeedCp);
