import * as math from 'mathjs';

// same behavior as old mathjs squeeze function
export function squeezeInnerOneLevel(matrix) {
    function squeezeOne(arr) {
        if (Array.isArray(arr)) {
            return arr.map(el => {
                if (Array.isArray(el) && el.length === 1) {
                    return el[0];
                }
                return el;
            });
        }
        return arr;
    }

    const array = matrix.toArray();
    const squeezedArray = squeezeOne(array);
    return math.matrix(squeezedArray);
}