import React, {Component} from 'react';
import {Row, Col, FormGroup, Form, DropdownButton, Dropdown, InputGroup, FormControl} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../../store.js';

class CommandFormAuxiliaryPointAndTargetPose extends Component {

    velocityChange(val) {
        this.props.command.data.velocity = parseInt(val, 10);
        store.commandValueChanged();
    }

    maskChange(e) {
        let checked = e.target.checked;
        let dataindex = e.target.getAttribute('dataindex');
        this.props.command.data.mask[dataindex] = checked;
        if (!checked) {
            this.props.command.data.cartesianTargetPose[dataindex] = '';
        }
        store.commandValueChanged();

    }

    ctpChange(e) {
        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            this.props.command.data.cartesianTargetPose[e.target.getAttribute('dataindex')] = e.target.value;
            store.commandValueChanged();
        }
    }

    apChange(e) {
        if (/^-?\d*\.?\d*$/.test(e.target.value)) {
            this.props.command.data.auxiliaryPoint[e.target.getAttribute('dataindex')] = e.target.value;
            store.commandValueChanged();
        }
    }

    render() {
        let command = this.props.command;
        let auxiliaryPointData = ['x', 'y', 'z'];
        let cartesianData = ['x', 'y', 'z', 'A', 'B', 'C'];
        let {maskChange, ctpChange, apChange} = this;

        return (
            <div>
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <Form.Label>
                                motion profile
                            </Form.Label>
                            <div>
                                <DropdownButton
                                    title={command.data.velocity === 1 ? 'ramp' : 'sinoid'}
                                    id='selectVelocity'
                                    onSelect={this.velocityChange}
                                >
                                    <Dropdown.Item eventKey="1">ramp</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">sinoid</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={12}>
                        <Form.Label>
                            auxiliary point
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    {auxiliaryPointData.map(function (label, i) {
                        if(!store.robot.data.preDefinedMask || store.robot.data.preDefinedMask[i] === true) {
                            return (
                              <Col xs={4} key={label}>
                                  <FormGroup>
                                      <InputGroup>
                                          <InputGroup.Text>
                                              {command.type.includes('REL') ? (
                                                <span>&Delta;</span>
                                              ) : ''}
                                              {label}
                                              {['x', 'y', 'z'].includes(label) ? (
                                                <sub>0</sub>
                                              ) : ''}
                                          </InputGroup.Text>
                                          <FormControl type="text" dataindex={i} onChange={apChange}
                                                       value={command.data.auxiliaryPoint[i]}/>
                                          <InputGroup.Text>{['x', 'y', 'z'].includes(label) ? 'm' : 'deg'}</InputGroup.Text>
                                      </InputGroup>
                                  </FormGroup>
                              </Col>
                            )
                        }
                    })}
                </Row>
                <Row className="mt-3">
                    <Col xs={12}>
                        <Form.Label>
                            target pose
                        </Form.Label>
                    </Col>
                </Row>
                <Row>
                    {cartesianData.map(function (label, i) {
                        if(!store.robot.data.preDefinedMask || store.robot.data.preDefinedMask[i] === true) {
                            return (
                                <Col xs={4} key={label} className="mb-2">
                                    <FormGroup>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                {command.type.includes('REL') ? (
                                                    <span>&Delta;</span>
                                                ) : ''}
                                                {label}
                                                {['x', 'y', 'z'].includes(label) ? (
                                                    <sub>0</sub>
                                                ) : ''}
                                            </InputGroup.Text>
                                            {!store.robot.data.preDefinedMask ? (
                                                <InputGroup.Text>
                                                    <input type="checkbox" dataindex={i} onChange={maskChange}
                                                           checked={command.data.mask[i]}
                                                           disabled={command.data.mask[i] !== true && command.data.mask.filter(x => x === true).length >= store.robot.data.jointAmount}/>
                                                </InputGroup.Text>
                                            ) : null }
                                            <FormControl type="text" dataindex={i} onChange={ctpChange}
                                                         value={command.data.cartesianTargetPose[i]}
                                                         disabled={!command.data.mask[i]}/>
                                            <InputGroup.Text>{['x', 'y', 'z'].includes(label) ? 'm' : 'deg'}</InputGroup.Text>
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                            )
                        }
                    })}
                </Row>
            </div>
        )
    }
}

export default easyComp(CommandFormAuxiliaryPointAndTargetPose);
