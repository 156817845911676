import React, {Component} from 'react';
import {ListGroupItem} from 'react-bootstrap';
import {easyComp} from 'react-easy-state/dist/umd.es6';
import store from '../../store.js';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTrash} from "@fortawesome/free-solid-svg-icons";

class Command extends Component {
    removeCommand(e) {
        store.commands.splice(parseInt(e.target.getAttribute('index'), 10), 1);
        store.refreshCommandsIndex();
        store.commandValueChanged();
    }

    render() {
        let command = this.props.command;
        return (
          <ListGroupItem index={command.index} commandid={command.id} onClickCapture={this.props.onClickHandler}
                         className={`d-flex ${parseInt(store.selectedCommand, 10) === command.id ? 'active' : ''}`}>
            <span className="sort-handle m-r">
              <FontAwesomeIcon icon={faBars}/>
            </span>
            <span className="command-title" commandid={command.id}>
              {command.type && store.robot.data ? (
                command.type + ' ' + (command.data ? command.data.toString(store.robot.data.jointAmount) : '')
              ) : <i commandid={command.id}>nicht definiert</i>}
            </span>
            <span className="ms-auto" isremovebutton="true" role="button" onClick={this.removeCommand} index={command.index}>
              <FontAwesomeIcon isremovebutton="true" index={command.index} icon={faTrash} style={{pointerEvents: 'none'}}/>
            </span>
          </ListGroupItem>
        )
    }
}

export default easyComp(Command);
